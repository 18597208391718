import React, { useState, useEffect } from 'react';
import dataProvider from '../../lib/dataProvider';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate } from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Calendar from '../../lib/react-calendar/Calendar.tsx';
import fixStyles from './styles.module.css'; // Ensure to have this in your project structure
import moment from 'moment';
import 'moment/dist/locale/de';


const CalCalendar = (props) => {
  const tr = useTranslate();
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(2, 'month'))
  const [dates, setDates] = useState({});
  const [data, setData] = useState({});
  const [menuItems, setMenuItems] = useState({preis: [], zimmer: [], online: [], zusatz: []});
  const [menuState, setMenuState] = useState({preis: 0, zimmer: 0, online: 0, zusatz: 0});
  const [weekdays, setWeekdays] = useState([true, true, true, true, true, true, true]);
  const [months, setMonths] = useState(3);
  const [rDaily, setRDaily] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [click, setClick] = useState([new Date(), new Date()]);
  const [range, setRange] = useState([new Date(), new Date()]);
  const [legend, setLegend] = useState({preis: {}, zimmer: {}, online: {}, zusatz: {}});
  const [undoData, setUndoData] = useState({});
  const [undoIdx, setUndoIdx] = useState(0);

  moment.locale('de');

  const getMenuItems = (items) => {
    const ret = []
    for(const ik in items){
      if(items[ik]?.dis === 0){
        ret.push(<MenuItem sx={{backgroundColor:items[ik]?.farbe}} value={items[ik]?.id}>{items[ik]?.bezeichnung}</MenuItem>)
      }
    }
    return ret
  }

  useEffect(() => {
    dataProvider.update('calCalendar', {id:0 })
    .then(res  => {
      const items = menuItems
      setData(res.data)
      if(res?.data?.preis){
        items.preis = getMenuItems(res.data.preis)
      }
      if(res?.data?.zimmer){
        const ms = menuState
        ms['zimmer'] = Object.entries(res.data.zimmer)[0][0]
        setMenuState(ms)
        items.zimmer = getMenuItems(res.data.zimmer)
      }
      if(res?.data?.online){
        items.online = getMenuItems(res.data.online)
      }
      if(res?.data?.zusatz){
        items.zusatz = getMenuItems(res.data.zusatz)
      }
      setMenuItems(items)
    })
  }, []);

  useEffect(() => {
    dataProvider.update('calCalendar/getdates', {id:0, data:{startDate: startDate, months: months} })
    .then(res  => {
      setDates(res.data)
    })
  }, [startDate, months]);

  const assignDates = () => {
    const uData = undoData
    uData[undoIdx] = {startDate: startDate, months: months, weekdays: weekdays, range: range, dates: dates}
    dataProvider.update('calCalendar/assigndates', {id:0, data:{startDate: startDate, months: months, types: menuState, weekdays: weekdays, range: range} })
    .then(res  => {
      setUndoIdx(undoIdx+1)
      setUndoData(uData)
      setDates(res.data)
    })
  }

  const undo = () => {
    dataProvider.update('calCalendar/undo', {id:0, data: undoData[undoIdx-1] })
    .then(res  => {
      console.log(res)
      setUndoIdx(undoIdx-1)
      setDates(res.data)
    })
  }

  const handleSetRange = (val, ev) => {
    const r = [...range]
    const cl = [...click]
    cl[0] = cl[1]
    cl[1] = val[1]?val[1]:val[0]
    if(cl[0] < cl[1]){
      r[0] = cl[0]
      r[1] = cl[1]
    }
    else {
      r[0] = cl[1]
      r[1] = cl[0]
    }
    setClick(cl)
    setRange(r)
  }
  
  const DisplayLegend = ({calType}) => {
    const lDiv = []
    for(const lk in legend[calType]){
      lDiv.push(
        <div style={{display: 'flex'}}>
          <div className={fixStyles.reactCalendarLegendTile} style={{backgroundColor: legend[calType][lk]}} />
          <div>{data[calType][lk].bezeichnung}</div>
        </div>
      )
    }

    return (
      <div style={{marginBottom: '0.7em'}}>
        <div style={{fontWeight: 'bold', textAlign: 'left'}}>
          {tr('bw.cal.calendar.'+calType)}
        </div>
        <div>
          { calType === 'preis' && <div style={{ display: 'flex' }} >
            <div className={fixStyles.reactCalendarLegendTile} style={{ 
              backgroundColor: 'white', border: '1px solid #000',
              backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.5) 30%)',
              backgroundSize: '1em 1em',
            }} />
            <div>{tr('bw.cal.calendar.mod1')}</div>
          </div>}
          { calType === 'preis' && <div style={{ display: 'flex' }} >
            <div className={fixStyles.reactCalendarLegendTile} style={{ 
              backgroundColor: 'white', border: '1px solid #000',
              backgroundImage: 'linear-gradient(135deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.5) 30%)',
              backgroundSize: '1em 1em',
            }} />
            <div>{tr('bw.cal.calendar.mod2')}</div>
          </div>}
          <div style={{display: 'flex'}}>
            <div className={fixStyles.reactCalendarLegendTile} style={{backgroundColor: 'white', border: '1px solid #000'}} />
            <div>{tr('bw.cal.calendar.notassigned')}</div>
          </div>
          { lDiv }
        </div>
      </div>
    )
  }
  
  const DateRangePicker = ({calType}) => {
    const [value, setValue] = useState(menuState[calType]);
    if(calType === 'preis'){
      setRDaily(false)
    }

    const formatDate = (date) => {
      return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
    }
    
    const getDayClass = ({date, view, ...rest}) => {
      const classes = [fixStyles.calCalendarTile]
      if(date >= range[0] && date <= range[1] && weekdays[date.getDay()]){
        classes.push(fixStyles.selectionTile)
      }
      return classes
    }
    const getTileContent = ({activeStartDate, date, view}) => {
      if(date >= range[0] && date <= range[1] && weekdays[date.getDay()]){
        return <div className={fixStyles.selectionWrapper}><span className={fixStyles.selection} /></div>
      }
      return
    }
    const getTileStyle = ({date, view, ...rest}) => {
      const style = {}
      const fdate = formatDate(date)
      if(date.getDay() === 0){
        style.fontWeight = 'bold'
      }
      if(dates && dates[fdate] && dates[fdate]['id_'+calType+'_gr']){
        const gr_id = dates[fdate]['id_'+calType+'_gr']
        if(data && data[calType] && data[calType][gr_id]){
          style.backgroundColor = data[calType][gr_id].farbe
          if(calType === 'preis'){
            if(data[calType][gr_id].mod === 1){
              style.backgroundImage = 'linear-gradient(45deg, rgba(255,255,255,0) 75%, rgba(0,0,0,0.5) 25%)'
              style.backgroundSize = '3em 3em'
              setRDaily(true)
            }
            if(data[calType][gr_id].mod === 2){
              style.backgroundImage = 'linear-gradient(135deg, rgba(255,255,255,0) 75%, rgba(0,0,0,0.5) 25%)'
              style.backgroundSize = '3em 3em'
              setRDaily(true)
           }
          }
        }
        if((!legend[calType] || !legend[calType][gr_id]) && data && data[calType] && data[calType][gr_id] && (!data[calType][gr_id].id_parent || data[calType][gr_id].farbe !== data[calType][data[calType][gr_id].id_parent].farbe)){
          const le = legend;
          le[calType][gr_id] = data[calType][gr_id].farbe
          setLegend(le)
        }
      }
      return style
    }
    const getTileTitle = ({date, view, ...rest}) => {
      const fdate = formatDate(date)
      if(dates && dates[fdate] && dates[fdate]['id_'+calType+'_gr']){
        const gr_id = dates[fdate]['id_'+calType+'_gr']
        if(data && data[calType] && data[calType][gr_id]?.bezeichnung){
          return data[calType][gr_id].bezeichnung
        }
      }
      return
    }

    return (
      <div style={{width:'280px', paddingRight:'1em'}}>
        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
          {tr('bw.cal.calendar.'+calType)}
        </div>
        <div>
          <Select
            value={value}
            onChange={(ev) => {const ms = menuState;ms[calType] = ev.target.value;setMenuState(ms);setValue(ev.target.value)}}
            size="small"
            sx={{width: '100%'}}
          >
            { calType === 'preis' && <MenuItem value={-1} disabled={!rDaily}>{tr('bw.cal.calendar.resetdaily')}</MenuItem>}
            { calType !== 'zimmer' && <MenuItem value={0}>{tr('bw.cal.calendar.nochange')}</MenuItem>}
            { menuItems[calType] }
          </Select>
        </div>
        <Calendar 
          activeStartDate={new Date(startDate.format('YYYY-MM-DD'))}
          onChange={handleSetRange} 
          value={range} 
          className={fixStyles.reactCalendar}
          tileClassName={getDayClass}
          tileContent={getTileContent}
          tileStyle={getTileStyle}
          tileTitle={getTileTitle}
          selectRange
          allowPartialRange
          showDoubleView={months}
          showFixedNumberOfWeeks={false}
          showNeighboringMonth={false}
          showNavigation={false}
          />
      </div>
    );
  };
  
  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div style={{display:'flex', justifyContent: 'space-between', paddingBottom: '1em'}}>
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment} >
            <DatePicker sx={{mt: 0, mb: 0, width: '11em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }},
            }}
              label={tr('bw.cal.calendar.monthfrom')}
              views={['month','year']}
              defaultValue={startDate}
              onChange={(ev) => {setStartDate(ev)}}
            />
            <DatePicker sx={{mt: 0, mb: 0, ml: '1em', width: '11em',
              '& .MuiInputBase-root': { height: '40px', padding: '0 14px' },
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottom: 'none' },
              '& .MuiInput-underline': { '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }},
            }}
              label={tr('bw.cal.calendar.monthto')}
              views={['month','year']}
              value={endDate}
              onChange={(ev) => {setMonths(ev.diff(startDate, 'months')+1); setEndDate(ev)}}
            />
          </LocalizationProvider>
          <Button variant="contained" color="primary" value="2 Months" onClick={()=>{setMonths(2);setEndDate(moment(startDate).add(1, 'months'))}} style={{marginLeft:'1em'}}>{"2 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="3 Months" onClick={()=>{setMonths(3);setEndDate(moment(startDate).add(2, 'months'))}} style={{marginLeft:'1em'}}>{"3 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="6 Months" onClick={()=>{setMonths(6);setEndDate(moment(startDate).add(5, 'months'))}} style={{marginLeft:'1em'}}>{"6 "+tr('bw.cal.calendar.months')}</Button>
          <Button variant="contained" color="primary" value="12 Months" onClick={()=>{setMonths(12);setEndDate(moment(startDate).add(11, 'months'))}} style={{marginLeft:'1em'}}>{"12 "+tr('bw.cal.calendar.months')}</Button>
        { isHauptadmin() && <div style={{ marginLeft: '.8em', display: 'inline'}}><Checkbox checked={showRooms} onChange={(ev)=>{ setShowRooms(ev.target.checked) }} />{tr('bw.cal.calendar.zimmer')}</div> }
        </div>
      </div>
      <div style={{display:'flex', justifyContent: 'flex-start', paddingBottom: '1em'}}>
        <div style={{ lineHeight:'2.5em'}}>{tr('bw.cal.calendar.startdate')}: <b>{moment(range[0]).format('YYYY-MM-DD')}</b></div>
        <div style={{ marginLeft:'1em', lineHeight:'2.5em'}}>{tr('bw.cal.calendar.enddate')}: <b>{moment(range[1]).format('YYYY-MM-DD')}</b></div>
        { undoIdx >0 && <Button variant="contained" color="primary" value="assign" onClick={()=>{undo()}} style={{marginLeft:'1em'}}>{tr('bw.cal.calendar.undo')+undoIdx}</Button> }
        <Button variant="contained" color="primary" value="assign" onClick={()=>{assignDates()}} style={{marginLeft:'1em'}}>{tr('bw.cal.calendar.assign')}</Button>
        <div style={{ marginLeft:'2em', lineHeight:'2.5em'}}>{tr('bw.cal.calendar.weekdays')}:</div>
        <div><Checkbox checked={weekdays[1]} onChange={(ev)=>{ const wd = [...weekdays]; wd[1] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.mon')}</div>
        <div><Checkbox checked={weekdays[2]} onChange={(ev)=>{ const wd = [...weekdays]; wd[2] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.tue')}</div>
        <div><Checkbox checked={weekdays[3]} onChange={(ev)=>{ const wd = [...weekdays]; wd[3] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.wed')}</div>
        <div><Checkbox checked={weekdays[4]} onChange={(ev)=>{ const wd = [...weekdays]; wd[4] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.thu')}</div>
        <div><Checkbox checked={weekdays[5]} onChange={(ev)=>{ const wd = [...weekdays]; wd[5] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.fri')}</div>
        <div><Checkbox checked={weekdays[6]} onChange={(ev)=>{ const wd = [...weekdays]; wd[6] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.sat')}</div>
        <div><Checkbox checked={weekdays[0]} onChange={(ev)=>{ const wd = [...weekdays]; wd[0] = ev.target.checked; setWeekdays(wd); }} />{tr('glob.day.short.sun')}</div>
      </div>
      <div style={{display:'flex', justifyContent: 'flex-start'}}>
        <DateRangePicker calType="preis" />
        { showRooms && <DateRangePicker calType="zimmer" /> }
        <DateRangePicker calType="online" />
        <DateRangePicker calType="zusatz" />
        <div>
          <DisplayLegend calType="preis" />
          { showRooms && <DisplayLegend calType="zimmer" /> }
          <DisplayLegend calType="online" />
          <DisplayLegend calType="zusatz" />
        </div>
      </div>
      </CardContent>
    </Paper>         
  )
}
export default CalCalendar;