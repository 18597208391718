import React, { useState } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useRecordContext, useTranslate, List, Filter, Edit, Datagrid, SimpleForm, 
  TextField, ReferenceField, TextInput, EditButton } from 'react-admin';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';

const ListForm = (props) => {
  const MoveCond = (props) => {
    const record = useRecordContext();
    return record ? <Move {...props} /> :null;
  };

  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} title={('bw.set.roomcattext.title')}  sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <MoveCond {...props} getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setRoomcattext" />
        <TextField source="bezeichnung" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <span style={{ color: 'grey', fontSize:'small' }}>{translate('bw.set.servicetext.title')}{record?.bezeichnung}:</span>
      <TextInput source="text_de" sx={{ width: '90%' }} />
      <TextInput source="text_en" sx={{ width: '90%' }} />
      <TextInput source="text_fr" sx={{ width: '90%' }} />
      <span style={{ color: 'grey', fontSize:'small' }}>{translate('bw.set.servicetext.textBWO')}:</span>
      <span style={{ marginLeft: '.5em' }}>
        <ReferenceField source="id" reference="bwhService" link={false}>
          <TextField source="kurzbeschr_de" /><br />
          <TextField source="kurzbeschr_en" /><br />
          <TextField source="kurzbeschr_fr" />
        </ReferenceField>
      </span>
    </SimpleForm>
  )
};

const ListFilter = (props) => (
  <Filter {...props} >
    <TextInput source="bezeichnung" alwaysOn />
  </Filter>
);

const Servicetext = {
  list: (props) => (
    <ListForm {...props} filters={<ListFilter />} />
  ),
  
  edit: (props) => (
    <Edit title='bw.bwh.image.titleEdit' mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Servicetext