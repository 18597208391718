import React from 'react';
import { getGlobalData } from '../../lib/globalData';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, List, Filter, Create, Edit, Datagrid, SimpleForm, required,
  TextField, BooleanField, ReferenceManyField, SingleFieldList, ChipField, SelectField, 
  EditButton, TextInput, BooleanInput, SelectArrayInput, ReferenceArrayInput, SelectInput } from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';

const bworateoption = [
  { id: 0, name: 'bw.set.rate.nobwo' },
  { id: 1, name: 'bw.set.rate.bwoactive' },
  { id: 2, name: 'bw.set.rate.bwochange' },
];
/*
const rateActionChoices = [
  { id: 0, name: 'bw.set.rate.actionNo' },
  { id: 1, name: 'bw.set.rate.actionCancel' },
];
*/
const ListForm  = (props) => {
  return (
    <List {...props} title='bw.set.rate.title' 
      sort={{ field: 'bezeichnung', order: 'ASC' }} exporter={isHauptadmin() && props.exporter} 
      pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <TextField source="bezeichnung" />
        <ReferenceManyField reference="setCondpayreq" source="id" target="id_condition" sort={{ field: 'id', order: 'ASC' }} filter={{ typ: 0 }}>
            <SingleFieldList linkType={false}>
                <ChipField source="payrequest" />
            </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField label="bw.set.rate.cancelpoints"  reference="setCondpayreq" source="id" target="id_condition" sort={{ field: 'id', order: 'ASC' }} filter={{ typ: 1 }}>
            <SingleFieldList linkType={false}>
                <ChipField source="payrequest" />
            </SingleFieldList>
        </ReferenceManyField>
        <SelectField source="bwo_rate" choices={bworateoption} />
        <BooleanField source="default_rate" />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}
/*
          <TextField source="erinnerung" textAlign="right" />
          <TextField source="frist" textAlign="right" />
          <SelectField source="aktion" choices={rateActionChoices} />
*/

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const info = getGlobalData('info');
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
        <TextInput source="bezeichnung" fullWidth />
        <ReferenceArrayInput source="id_payrequest" reference="setPayrequest">
          <SelectArrayInput label={translate("bw.set.rate.id_payrequest")} optionText="payrequest" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="id_storno" reference="setPayrequest">
          <SelectArrayInput label={translate("bw.set.rate.id_storno")} optionText="payrequest" />
        </ReferenceArrayInput>
        {info.bwh_sprachen.map((sp)=>(
          <TextInput source={'titel_'+sp} label={translate("bw.bwh.rate.titelField")+" "+sp} key={sp} fullWidth />
        ))}
        <SelectInput source="bwo_rate" choices={bworateoption} validate={required()}/>
        <BooleanInput source="default_rate"  />
    </SimpleForm>
  )
};
/*
        <NumberInput source="erinnerung"  />
        <NumberInput source="frist"  />
        <SelectInput source="aktion" choices={rateActionChoices} />
*/

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="bezeichnung" alwaysOn />
    </Filter>
);

const Rate = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.rate.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.rate.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Rate;