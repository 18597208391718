import React from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useRecordContext, Create, Edit, SimpleForm, ReferenceInput, BooleanInput, 
        SelectInput, TextInput } from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import { parse } from 'query-string';

const redirect = (ressource, id, data) => {
  console.log([ressource, id, data])
  if(!data) {
    return "setRoom/";
  }
  return `setRoom/${data.id_zimmer}/edit`;
};

const CForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const params = window.location.hash.split('?');
  const { id_zimmer: id_zimmer_string } = parse(params[1]);
  const id_zimmer = id_zimmer_string ? parseInt(id_zimmer_string, 10) : '';
  return (
    <SimpleForm defaultValues={{ id_zimmer_neu:id_zimmer, id_zimmer }} toolbar={<CloseToolbar nextstep={redirect} redir={"/setRoom/"+id_zimmer} />} {...props}>
      <ReferenceInput source="id_zimmer" reference="setRoom" link={false} perPage={1000} >
        <SelectInput optionText="bezeichnung" disabled/>
      </ReferenceInput>
      <TextInput source="buchstabe" />
      <BooleanInput source="matratze" />
      <TextInput source="bemerkung" />
    </SimpleForm>
  )
};

const EForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const record = useRecordContext();
console.log(record);
  return (
    <SimpleForm toolbar={<CloseToolbar redir={"/setRoom/"+record.id_zimmer} />} {...props}>
      <ReferenceInput source="id_zimmer" reference="setRoom" link={false} perPage={1000}>
        <SelectInput optionText="bezeichnung" disabled />
      </ReferenceInput>
      <TextInput source="buchstabe" />
    {isHauptadmin()
      ?<SelectInput source="matratze" choices={[{id:0,name:'normal'},{id:1,name:'matratze'},{id:2,name:'gelöscht'}]}/>
      :<BooleanInput source="matratze" />
      }  
      <TextInput source="bemerkung" />
    </SimpleForm>
  )
};
      
const Bed ={
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.bed.titleCreate" redirect={redirect} >
      <CForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.bed.titleEdit" mutationMode="pessimistic" {...props} redirect={redirect}>
      <EForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Bed