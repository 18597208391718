const langDE =
{
  bw: {
    bwh: {
      agent: {
        title: 'Anzeige Einstellungen pro Agent',
        NetPrice: 'Netto Preis (ohne Buchungsgebühr)',
        NetPriceFee: 'Netto Preis inkl. Buchungsgebühr',
        NetPriceService: 'Netto Preis + fixe Zusatzleistungen',
        GrossPrice: 'Brutto Preis',
        NoResizer: 'Keine Anpassung',
        avPreview: 'Verfügbarkeit- / Preisvorschau',
        PriceNoComm: 'Preis auf Startseite',
        showavgprice: 'Zeige Durchschnittspreis statt Gesamtpreis',
        showtotservice: 'Zeige Totalpreis für Zusatzleistungen',
        resize: 'iframe resizer',
        css: 'Eigenes CSS',
        cssupload: 'CSS Datei hochladen',
        cssremove: 'entfernen',
        redirect: 'Umleitung zu URL',
        avPreviewK: 'Vorschau',
        showavgpriceK: 'Tagespreis',
        resizeK: 'Resizer',
        cssK: 'CSS',
        redirectK: 'Umleitung',
        intLink: 'Website Integration als Link',
        intWidget: 'Website Integration als Widget',
        intIFrame: 'Website Integration als iFrame',
        embedtitle: 'BookWorldOnline in deine Website einbinden',
        language: 'Sprache',
        book: 'Buchung durch Agenten',
        noheadimg: 'Keine Kopf Bilder',
        embed: 'Platziere diesen code auf deiner Website',
        link: 'Platziere einen link zu dieser URL auf deiner Website',
      },
      discount: {
        title: 'Rabatt Codes',
        titleCreate: 'Rabatt Codes erstellen',
        titleEdit: 'Rabatt Codes bearbeiten',
        prozent: 'Prozent',
        einmalig: 'Einmalig',
        nacht: 'pro Nacht',
        person: 'pro Person',
        person_nacht: 'pro Person und Nacht',
        neu: 'Neu',
        benutzt: 'Benutzt',
        inaktiv: 'Inaktiv',
        ankunft: 'Ankunft',
        dauer: 'Dauer',
        personen: 'Gäste',
        ab: 'ab',
        bisdatum: 'bis',
        bis: 'bis',
        force_agent: 'Wechsle Agent zu',
        dontchangeangent: 'kein Wechsel',
        error: {
          code: 'Nur Kleinbuchstaben, Zahlen und Unterstrich',
          codematch: 'Für den Code<%{code}> Rabatt muss <%{rabatt}> und Typ muss <%{typ}> sein.',
        },
      },
      guestfield: {
        title: 'Gastfelder',
        titleEdit: 'Gastfelder Bearbeiten',
        disp_opts: 'Anzeigeoptionen',
        checkin: 'Check In',
        checkinlabel: 'Online Checkin',
        noShow: 'Nicht Anzeigen',
        orig_text: 'Originaltext',
        show: 'Anzeigen',
        req: 'Obligatorisch',
        reqWoCc: 'Obligatorisch, wenn keine KK notwendig',
        noCheckin: '-',
        mainOpt: 'Hauptgast',
        allOpt: 'Alle Gäste',
        mainReq: 'Haupt (pflicht)',
        allReq: 'Alle (pflicht)',
        options: 'Werte zur Auswahl',
        optionsdesc: 'Eine Optionen pro Zeile ( Schlüssel mit = vorangestellt )',
        validatedesc: 'email or regex',
      },
      hostel: {
        bedingung1: 'Bedingung 1',
        bedingung2: 'Bedingung 2',
        bedingung3: 'Bedingung 3',
        zahlunggelesen: 'Zahlungsformular-Checkbox',
        zahlunggeleseninfo: 'Zahlungsformular-Checkbox-Information',
        confirmtext: 'Buchung-Bestätigung',
        showbookingtext: 'Buchung-Anzeige',
        confirminfotext: 'Bestätigungs-Information',
        cancelreservationtext: 'Stornierungs-Bestätigung',
        privacy: 'Datenschutz',
        contact: 'Impressum',
        resultfound: 'Angebot gefunden',
        noresultfound: 'Kein Angebot gefunden',
        arrivaltimeoptions: 'Ankunftszeit Optionen',
        booknow: 'Button buchen',
        booknowweiter: 'Button buchen weiter',
        booknowfinish: 'Button buchen abschliessen',
        bild: 'Kopfbild',
        map: 'Karte Hostel',
        zusatzbild: 'Zusatzbild',
        favicon: 'Favicon',
      },
      image: {
        title: 'Hostelbilder',
        titleEdit: 'Bild Bearbeiten',
        titleCreate: 'Bild hinzufügen',
        art: 'Bildart',
        pos: 'Position',

        error: {
          invalidPos: 'This position already exists. Please use another position.',
        },
      },
      rate: {
        titelField:'Titel',
      },
      roomcategory: {
        title: 'Zimmerkategorien',
        titleEdit: 'Zimmer Bearbeiten',
        all: 'Alle',
        bezeichnung: 'Kategorie',
        titel: 'Titel',
        kurzbeschr: 'Kurzbeschreibung',
        beschr: 'Beschreibung',
        onbwh: 'Online',
        associated_rooms: 'Zugeordnete Zimmer',
        units: 'Einheiten (z.B. "Bett/Betten/Person/Personen")',
      },
      service: {
        title: 'Zusatzleistungen',
        titleEdit: 'Zusatzleistung Bearbeiten',
        bezeichnung: 'Zusatzleistung',
        titel: 'Titel',
        kurzbeschr: 'Kurzbeschreibung',
        onbwh: 'Online',
        free_cancel_list: 'Free Cancel',
        free_cancel: 'Keine Storno Kosten',
        anz_modifier: 'Bedingung',
        deactivate: 'Durch setzen des Titels auf "NotActive", wird die Zusatzleistung für diese Sprache deaktiviert.',
      },
      settings: {
        titleEdit:'BookWorldOnline Einstellungen bearbeiten',
        grund: 'Basis',
        konfig: 'Konfiguration',
        konfig2: 'Konfiguration 2',
        raten: 'Raten',
        inaktiv:'Inaktiv',
        test:'Test',
        aktiv:'Aktiv',
        haupt:'Hauptsystem',

        MixedOnly: 'Nur Gemischte Zimmer',
        MixedOnlyAndFemale: 'Gemischt und Frauen',
        FemaleOnly: 'Nur Frauen',
        AllPossibilities: 'Alle Möglichkeiten',
        NoGenderFilter: 'Kein Geschlecht-Filter',
        NoDorm: 'Keine Mehrbettzimmer',
        NoFilter: 'Kein Unterkunftsfilter',

        No: 'Nein',
        Optional: 'Optional',
        Mandatory: 'Obligatorisch',
        identDesc: 'Mit dem BookWorldOnline System erhalten Sie automatisch die Internetadresse (URL) <a href="https://%{bwh_ident}.%{bwh_domain}" target="_blank">%{bwh_ident}.%{bwh_domain}</a>.',
        bwh_def_personen: 'Standardanzahl Personen',
        bwh_anz_personen: 'Maximale Anzahl Personen',
        bwh_childalterskat: 'Alterskategorie für Kinderpreise',
        bwh_childalterskat_helpertext: 'Alterskategorie für Kinderpreise',
        bwh_childinprivat: 'Kinder im Privatzimmer erlaubt',
        bwh_childinprivat_helpertext: 'Kinder im Privatzimmer erlaubt',
        bwh_childindorm: 'Kinder im Dorm erlaubt',
        bwh_childindorm_helpertext: 'Kinder im Dorm erlaubt',
        bwh_anz_naechte: 'maximale Anzahl Nächte',
        lastNewRes: 'Letzte neue Reservation',
        lastNewRes_helpertext: 'bis x Stunden vor 24:00 des Ankunftstages',
        lastChange: 'Letzte Änderung/Annullation',
        lastChange_helpertext: 'bis x Stunden vor 24:00 des Ankunftstages',
        bwh_genderchoice: 'Filter Geschlecht / Unterkunft',
        bwh_gastfeldstorelan: 'Buchungssprache in Gastfeld speichern',
        bwh_gastfeldstorelan_helpertext: 'Wählen Sie ein Gastfeld aus, in dem die Buchungssprache gespeichert wird',
        bwh_gelesenhacken: 'Anzahl Kontrollkästchen zum buchen',
        bwh_gelesenhacken_helpertext: 'Hier können z.B. Kontrollkästchen für Datenschutzanzeige etc. konfiguriert werden.',
        bwh_hideprivatmarker: 'Versteckte Privatzimmer-Markierung',
        bwh_hideprivatmarker_helpertext: 'Die Markierung als Privatzimmer wird bei den Angeboten versteckt',
        bwh_usearrivaltime: 'Ankunftszeit',
        bwh_checkintime: 'Früheste Check-in Zeit',
        bwh_checkouttime: 'Späteste Check-out Ziet',
        bwh_usearrivaltime_helpertext: 'Soll Gast Ankunftszeit angeben?',
        bwh_child_min_age: 'Minimales Kinder Alter',
        bwh_child_max_age: 'Maximales Kinder Alter',
        bwh_maplink: 'Spezieller Google map Link',
        bwh_mapapikey: 'Spezieller Google map API-Schlüssel',
        bwh_analyticskey: 'Google Analytics key',

        error: {
          DefaultLang: 'Die Standardsprache muss in der Liste der Sprachen vorkommen.',
          NoLang: 'In der Liste der Sprachen muss mindestens eine Sprache ausgewählt werden.',
          UseOnlyLetters: 'Kein gültiger Hostname. Es sind nur Kleinbuchstaben, Ziffern und Bindestrich erlaubt.',
          TheAddressAlreadyExists: 'Die Adresse existiert bereits.',
        },

        google: 'Google Optionen',

        BookingFee: 'Buchungsgebühr',
        noFee: 'keine Buchungsgebühr',
        worldPay: 'WorldPay',
        stripe: 'Stripe',
        storePaymentInfo: 'Zahlungsinfos speichern',
        noneExternal: 'keine Speicherung/extern',
        local: 'Lokal speichern',
        cvv: 'CVV-Nummer',
        noEntry: 'Keine Eingabe',
        optional: 'Optional',
        required: 'Erforderlich',
        prefAddGast: 'Anpassen von Gästen',
        update: 'existierende Gäste updaten',
        alwaysNew: 'immer neue Gäste erstellen',
        ccTypes: 'Kreditkarten',
        visa: 'Visa',
        master: 'Master',
        amex: 'AmEx',
        diners: 'Diners',

        MultiRoom: 'Multi-Room',
        SplitGroupInto2:  'Aufteilen der Gruppe in 2 Zimmer',
        Split2Ex : 'z.B. 4,3 / 3,3',
        SplitGroupInto3:  'Aufteilen der Gruppe in 3 Zimmer',
        Split3Ex : 'z.B. 4,3,2 / 3,2,2 / 2,2,2',
        SplitGroupIntoMoreThan3: 'Aufteilen der Gruppe in mehr als 3 Zimmer',
        SplitMoreEx : 'z.B. 3,2,2,2 / 4,3,2,2',
        anzsingle : 'Max. Anzahl Single',
        disInfo1: 'Multi-Room ermöglicht das Buchen von mehreren Privatzimmern mit einer Buchung.',
        disInfo2: 'Zur Zeit ist dieses Feature deaktiviert. Um es zu nutzen kontaktiere',

        roomchange: 'Zimmerwechsel',
        roomchange2: 'Erlaube Zimmerwechsel während Aufenthalt',
      },
      text: {
        title: 'Hosteltexte',
        titleEdit: 'Hosteltexte Bearbeiten',
        titleCreate: 'Hosteltexte Erstellen',
        imgTitle: 'Veranschaulichung',
        art: 'Textart',
        pos: 'Position',
        agent: 'Text für Agent',

        formatBlockTitle: 'Format Beschreibung',
        formatTitle: 'Formatierung',
        formatBold: '*fett*',
        formatItalic: '_kursiv_',
        formatHeading: 'h1. Heading',
        formatSubheading: 'h2. Subheading',
        ulTitle: 'Unordered List',
        ulOne: '* Item 1',
        ulTwo: '* Item 2',
        ulThree: '* Item 3',
        olTitle: 'Ordered List',
        olOne: '# Item 1',
        olTwo: '# Item 2',
        olThree: '# Item 3',
        allEntries: 'Alle Anzeigen',

        error: {
          wrongType: 'Sie können nur folgende Text-typen löschen: "Bestätigungs-Information" oder "Zahlungsformular-Checkbox-Information"',
          invalidPos: 'Diese Position is bereits belegt. Bitte wählen Sie eine Andere.',
        },
      },
    },
    cal: {
      calendar: {
        months: 'Monate',
        monthfrom: 'Datum von',
        monthto: 'Datum bis',
        startdate: 'Startdatum',
        enddate: 'Enddatum',
        weekdays: 'Wochentage',
        assign: 'Ausführen',
        preis: 'Preise',
        zimmer: 'Zimmer',
        online: 'Online',
        zusatz: 'Zusatzleistungen',
        notassigned: 'nicht zugewiesen',
        mod1: 'Rendite Manager',
        mod2: 'RoomPriceGenie',
        nochange: 'Keine änderung',
        resetdaily: 'Tagespreise zurücksetzen',
        undo: 'Undo '
      },
      mwst: {
        title: 'MwSt aufteilen: ',
        titleCreate: 'Neuer Eintrag',
        titleEdit: 'Eintrag bearbeiten',
        info: 'Liste der Teilbeträge, welche in den normalen Preisen inklusive sind, jedoch zu einem anderen MwSt-Satz versteuert werden. Der allgemeine Steuersatz ist oben vermerkt.',
        empty: 'Leerbett',
        all: 'Alle',

        preisgr: 'Preisgruppe',
        preiskat: 'Zimmerkategorie',
        alterskat: 'Alterskategorie',
        text_de: 'Text Deutsch',
        text_en: 'Text Englisch',
        text_fr: 'Text Französisch',
        betrag: 'Preis',
        type: 'Preistyp',
        mwst: 'MwSt',

        createInfo: 'Für jede dieser Feld-Kombinationen wird ein Eintrag angelegt, wenn nicht schon vorhanden.',

        fix: ' (Fix)',
        fixSep: ' (Fix), separiert',
        brut: '% (Brutto)',
        brutSep: '% (Brutto), separiert',
      },
      online: {
        title: 'Online-Gruppen',
        titleCreate: 'Neuer Eintrag',
        titleEdit: 'Eintrag bearbeiten',
        showall: 'Alle Anzeigen',
        compare: 'Vergleichen',
        onlinegroup: 'Online - Gruppe',

        copytitle: 'Online-Gruppe kopieren',
        copyinfo: 'Die Einträge der ausgewählten Gruppe werden in die neue Gruppe übernommen. Bitte geben Sie einen Namen für die neue Gruppe ein:',

        error: {
          deleteFailed: 'Online-Gruppe kann nicht gelöscht werden, da sie noch zwischen %{1} und %{2} verwendet wird.',
        },
      },
      onlinecompare: {
        details: 'Details (ID)',
        details2: 'Details (Bezeichnung)',
        strasse: 'Portal',
        pkbez: 'Zimmerkategorie',
        showall: 'Alle Anzeigen',
        zimmerb: 'Zimmer',
      },
      oportal: {
        title: 'Online Portale',
        titleCreate: 'Neuer Eintrag',
        titleEdit: 'Eintrag bearbeiten',
        firma: 'Portal',
        id_agent: 'Portal',
        id_preiskat: 'Zimmerkategorie',
        id_online_gr: 'Online Gruppe',
        farbe: 'Color',
        copyTitle: 'Neuer Name',
        kontingent: 'Kontingent / Bett(en)',
        freihalten: 'Freihalten / Bett(en)',
        min_nacht: 'Minimale Dauer',
        max_nacht: 'Maximale Dauer',
        noarrivalwd: 'Keine Ankunft',
        nodeparturewd: 'Keine Abreise',
        firstres: 'Erste neue Reservation',
        lastnewres: 'Letzte neue Reservation',
        lastchange: 'Letzte Änderung',
        lastminstart: 'Start',
        lastminend: 'Ende',
        room: 'Zimmer',
        online: 'Online',
        bed: 'Bett',
        sperr_belegung: 'Sperr Belegung*',
        sperr_belegung_info: '* Belegungen, welche im Eingabefeld rechts (kommagetrennt) eingegeben werden, sind online für das entsprechende Zimmer nicht verfügbar.',
        emptyPortal: 'Alle Portale',
        emptyRoomcat: 'Alle Raumkategorien',
        ankunftstag:'Ankunftstag',
        tag:'Tag',
        tage:'Tage',
        woche:'Woche',
        wochen:'Wochen',
        cleanup: 'Zuordnung entfernen',
        cleanupInfo: 'Die folgende Raum-Zuordnung wird entfernt:  |||| Die folgenden %{smart_count} Raum-Zuordnungen werden entfernt: ',
        multiedit: 'Multiedit',
        multicopy: 'Nach Online Gruppe kopieren',
        copyinfo: 'Setze das Feld',
        copyinfo2: 'für alle ausgewählten Zeilen auf den Wert',
        copyinfo3: 'Alle ausgewählten Zeilen werden in diese Online Gruppe kopiert:',
        MBookingInfo: '<b>Hauptbuchungszeitraum</b><br>Angaben in Tagen vor 24:00 des Ankunftstages (z.B. 0.75 => 06:00 am Ankunftstag)<br>Der Wert -1 in beiden Feldern entspricht den globalen Einstellungen.',
        SBookingInfo: '<b>Zweiter Buchungszeitraum</b> (Last Minute)<br>Angaben in Stunden vor 24:00 des Ankunftstages (z.B. 18 => 06:00 am Ankunftstag)<br>Der Wert -1 in beiden Feldern entspricht den globalen Einstellungen.',
        meField: 'Feld',
        meValue: 'neuer Wert',
        me: {
          kontingent: 'Kontingent',
          freihalten: 'Freihalten',
          min_nacht: '*Minimale Dauer',
          max_nacht: '*Maximale Dauer',
          noarrivalwd: '*keine Ankunft',
          nodeparturewd: '*keine Abreise',
          firstres: 'Hauptbuchungszeitraum -> Erste neue Reservation',
          lastnewres: 'Hauptbuchungszeitraum -> Letzte neue Reservation',
        },
        error: {
          copyMessage:'Einige Einträge waren bereits in der Zielgruppe. Sie wurden nicht überschrieben.',
          duplicateEntry: 'Doppelter Eintrag: Diese Kombination Agent/Zimmerkategorie existiert bereits.',
          validateSperrBelegung: "Ungültige Belegungsaufzählung: Nur Zahlen, 'm', 'f', 'x' und ',' erlaubt",
          validateFirstLastres: "Die Felder 'Erste neue Reservation' und 'Letzte neue Reservation' sollten beide oder keins auf -1 gesetzt sein.",
          validateLastminStartEnd: "Die Felder 'Start' und 'Ende' sollten beide oder keins auf -1 gesetzt sein.",
          missConfig: "Fehlerhafte Zuordnung gefunden - ",
          missConfig2: "Der folgende Raum ist zu einem falschen Raumtyp zugeordnet: |||| Die folgenden %{smart_count} Räume sind zu einem falschen Raumtyp zugeordnet: ",
        },
      },
      pricegr: {
        title: 'Preise',
        titleCreate: 'Neuer Eintrag',
        titleEdit: 'Preise',
        editMwst: 'MwST',
        editRate: 'Raten',
        filterTitle: 'Listen Filter:',
        showall: 'Alle Anzeigen',
        compare: 'Vergleichen',
        compareRate: 'Vergleichen (Rate)',
        compareVAT: 'Vergleichen (MwST)',
        createInfo: 'Für jede dieser Feld-Kombinationen wird ein Eintrag angelegt, wenn nicht schon vorhanden.',

        copytitle: 'Preisdefinition kopieren',
        copyinfo: 'Die Einträge der ausgewählten Definition werden in die neue übernommen. Bitte geben Sie einen Namen ein:',

        error: {
          deleteFailed: 'Preisdefinition kann nicht gelöscht werden, da sie noch zwischen %{1} und %{2} verwendet wird.',
        },
      },
      pricegrcompare: {
        bezeichnung: 'Bezeichnung',
        alterskat: 'Alterskategorie',
        belegung: 'Belegung',
        filterTitle: 'Listen Filter:',
        showall: 'Alle Anzeigen',
      },
      pricegrcomparerate: {
        rate: 'Rate',
      },
      rate: {
        title: 'Raten',
        preisgr: 'Preis Gruppe',
        titleCreate: 'Neuer Eintrag',
        titleEdit: 'Eintrag bearbeiten',
        info: 'Aktivierung und Preisanpassungen für Raten von verbundenen Systemen (Schnittstellen)',
        filterInfo: 'Anzeigen von Zimmer/Preise für Online-Gruppe:',

        name: 'Bezeichnung',
        rooms: 'Zimmer',
        price: 'Grundpreis',
        empty: '** Undefined **',
        copy: 'copy to all',
      },
      portal: {
        firma: 'Portal',
        kontingent: 'Kont.',
        freihalten: 'Freih.',
        id_preiskat: 'Zimmerkategorie',
        zimmer: 'Zimmer',
        min_nacht: 'Min',
        max_nacht: 'Max',
        noarrivalwd: 'k.Ank.',
        nodeparturewd: 'k.Abr.',
        firstres: 'Erst',
        lastnewres: 'Neu',
        lastchange: 'LM Change',
        lastminstart: 'LM Start',
        lastminend: 'LM End',
      },
      service: {
        title: 'Zusatzleistungen',
        titleCreate: 'Zusatzleistungen hinzufügen',
        titleEdit: 'Zusatzleistungen bearbeiten',
        showall: 'Alle Anzeigen',
        servicegroup: 'Zusatzleistung - Gruppe',

        copytitle: 'Zusatzleistungs-Gruppe kopieren',
        copyinfo: 'Die Preis-Einträge der ausgewählten Gruppe werden in die neue Gruppe übernommen. Bitte geben Sie einen Namen für die neue Gruppe ein:',

        error: {
          deleteFailed: 'Zusatzleistungsdefinition kann nicht gelöscht werden, da sie noch zwischen %{1} und %{2} verwendet wird.',
        },
      },
      serviceprice: {
        title: 'Zusatzleistungen Preise',
        titleCreate: 'Zusatzleistung erstellen',
        titleEdit: 'Zusatzleistung bearbeiten',
      },
      yield: {
        confirmTitel:'Änderungen nicht gespeichert.',
        confirmText:'Änderungen verwefen?',
        save:'Speichern',
        priceDisplay:'Preise anzeigen',
        def:'Standard',
        det:'Details',
        avg:'Durchschnitt der Buchungen',
        limits:'Grenzen',
        days:'Tage',
        date:'Datum',
        empty:'Leerbetten nicht zählen',
        groups:'Gruppen',
        occ:'Belegung',
      }
    },
    dashboard: {
      past: 'Früher',
      future: 'Zukunft',
      month:'1 Monat |||| %{smart_count} Monate',
      restricted_text:'<h2>Zugriff eingeschr&auml;nkt</h2><br><br>Der Zugriff ist aktuell eingeschr&auml;nkt.<br>Alle Systeme funktionieren im Hintergrund.<br>Bitte kontaktieren Sie uns Umgehend:<br> <br>+41-62-511-22-34<br><a href="mailto:support@book.world">support@book.world</a><br><br>',
      resolved:' behoben seit ',
      contact: {
        title:'Sende deine Anfrage',
        error:'Fehler! Nachricht konnte nicht gesendet werden.',
        property:'Kunde',
        user:'Benutzer',
        email: 'E-Mail',
        subject: 'Betreff',
        text: 'Text',
        sent: 'Nachricht wurde gesendet',
        send: 'Anfrage senden',
      },
      info_archive: {
        title: 'Archiv der Informationen und Meldungen'
      },
      incident_archive: {
        title: 'Archiv der Vorfälle', 
        incident: 'Störung: ',
        resolved: 'Behoben seit '
      },
      incident_edit: {
        title: 'Bearbeiten der Vorfälle', 
        startDate: 'Start Datum', 
        endDate: 'End Datum', 
        incident: 'Störung',
        maintenance: 'Wartung',
        breakdown: 'Ausfall',
        other: 'Sonstiges',
        update: 'Update',
        resolved: 'Behoben seit '
      }
    },
    if: {
      list: {
        titleAdditional: 'Zusätzlich verfügbare Schnittstellen',
      },
      portal: {
        tab1: 'Verfügbarkeiten',
        tab2: 'Aktionen',
        tab3: 'Einstellungen',
        UpdateRoomtypesText: 'Portal-Zimmerkategorien',
        UpdateRoomtypesButton: 'Start Import',
        chLoginname: 'Login-Name ändern',
        changePwd: 'Passwort ändern',
        ReservationHolen: 'Reservation von Portal importieren',
        importRes: 'Die Reservation wurde zum Importieren gekennzeichnet.',
        importRoomTypes: 'Die Zimmerkategorien werden importiert. Der Vollzug wird mit einer E-Mail gemeldet.',
        importRoomTypesHTML: 'Die Zimmerkategorien sind importiert.',
        GlobalRoomAssignment: 'Zimmerkategorienzuordnung',
        chGlobalRoomAssignment: 'Zuordnung speichern',
        ManualRoomInformation: 'Zimmer details',
        chManualRoomInformation: 'Zimmerinformationen speichern',
        roomsize: 'Zimmergrösse',
        roomcnt: 'Zimmeranzahl',
        defaultsize: 'mindest Belegung',
        prtAvailShow: 'Portal-Freigaben anzeigen (Anzahl Betten/Zimmer)',
        Einrichten: 'Einrichten',
        findHB_hostelnumber: 'Suche eine Reservation und verwende den Teil der Nummer vor dem "-" (z.B. HB-Nummer 39424-2980406, Hostelnummer 39424) '+
                             'oder das Hostel auf hostelbookers.com suchen und den Link zu den Detailinformationen kopieren und '+
                             'darin den Wert von "intpropertyid" auslesen.',
        InterfaceUp: 'Interface aktiv',
        InterfaceDown: 'Interface gestoppt',
        changeUp: 'start Interface',
        changeDown: 'stop Interface',
        updateAvailText: 'Verfügbarkeiten/Preise',
        updateAvailButton: 'neu setzen',
        updateAvailConfirm: 'Die Verfügbarkeiten und Preise werden neu gesetzt.',
        resetCacheText: 'Portal-Cache',
        resetCacheButton: 'Reset',
        resetCacheConfirm: 'Der Portalcache wurde zurückgesetzt.',
        FirstErrorResetText: 'FirstError',
        FirstErrorResetButton: 'Reset (FirstError)',
        FirstErrorResetConfirm: 'Der FirstError wird zurückgesetzt.',
        save_config_fields: 'Werte speichern',
        corruptTitel: 'Nicht konfiguriert',
        corruptText : 'Die Schnittstelle ist nicht richig konfiguriert. Bitte kontaktiere den Support.',
        bed:'Bett',
        beds:'Betten',
        room:'Zimmer',
        rooms:'Zimmer',
        days: 'Tage',
        ratelegende:'Raten: Preis (gerundet)',
        c_cribPrice:'crib price',
        c_meals:'meals',
        c_advance:'last new reservation (days)',
        c_guarantee:'guarantee',
        inboxPasswort:'Inbox user',
        inboxUser:'Inbox password',

        roomdetails:'Zimmerdetails',
        saveroomdetails:'Zimmerdetails speichern',
        gender0:'Mehrbett Frauen',
        gender1:'Mehrbett Männer',
        gender2:'Mehrbett Gemischt',
        gender4:'Privatzimmer',
        gender:'Geschlecht',

        timeSync:'Synchronisationsperiode:',
        timeNow: 'Aktuelle Zeit (Zeitzone):',
        timeLast: 'Letzte mögliche Reservation für Ankunft heute:',
        timeSet: 'setzen',
      }
    },
    glob: {
      hostel: {
        basis: 'Basis',
        abrechnung: 'Abrechnung',
        res_email_help: 'Diese E-Mail-Adresse wird für Reservationsbestätigungen verwendet (DormOnline und Interface).',
        koordinaten_help: 'Beispiel: -5.214569',
        max_dormbook_help: 'Lizenzen (pro Zusatzlizenz werden EURO 10.- pro Monat verrechnet)',
        validate_cc_text: 'Die Kreditkarten-Referenz darf nur die Grossbuchstaben A-Z, die Ziffern 0-9 sowie einen Leerschlag enthalten. Maximal 22 Zeichen.',
        validate_login_name: 'Ungültiger Login-Name. Nur Kleinbuchstaben und Zeichen \'_\' erlaubt. Der Login-Name muss mit einem Buchstaben beginnen.',
        validate_max_dormbook: 'Die Anzahl Lizenzen muss eine Ganzzahl grösser 1 sein.',
        copytitle: 'Hostel kopieren',
        copyhostel_info: 'Um ein Hostel zu kopieren # echo {hostel_id} >/var/log/alixon/copyhostel',
      }
    },
    rep: {
      bookingcom: {
        noFile: 'Keine Datei zum hochladen angegeben',
        WarnText: 'ACHTUNG: Die CSV Datei wird nur akzeptiert, wenn sie im Extranet mit englischer Spracheinstellung generiert wurde!',
        UploadText: 'Report von booking.com im CSV Format hochladen',
        StartCheck: 'Start Check',
        StartExcel: 'Start Excel',

        resStatement: 'Reservation Report',
        resOptions: 'Optionen',
        resCompareNetto: 'Vergleich von Betrag mit Netto-Betrag (Preis ohne Zusatzleistungen)',
        resComparePayment: 'Vergleich von Betrag mit Zahlungen (alle eingetragen Zahlungen)',
        payoutStatement: 'Payout Report',
      },
      documents: {
        download: 'herunterladen',
        show: 'anzeigen',
      },
      gdpr: {
        searchtitle: 'E-Mail oder Gast-ID',
        searchbutton: 'suchen',
        clearmailtxtbutton: 'Mailtext löschen',
        guestremarktitle: 'Gastbemerkung',
        General: 'General',
        reservations: 'Reservationen',
        resno: 'Res #',
        status: 'Status',
        arrival: 'Ankunft',
        departure: 'Abreise',
        remark: 'Bemerkung',
        erasereference: 'Referenz zur Löschanfrage',
        erasebutton: 'persönliche Daten Löschen',
        wronghostel: 'falsche Hostel-ID',
      },
      guestexport: {
        datafields: 'Auswahl Datenfelder',
        email: 'nur E-Mail',
        address: 'Postadresse',
        allfields: 'Alle Felder',
        gastcats: 'Auwahl Gastkategorien',
        allGuests: 'alle Gäste',
        resfilter: 'zusätzlicher Reservations-Filter',
        allres: 'alle Reservationen',
        arrival: 'Ankünfte',
        stay: 'Aufenthalt',
        departure: 'Abreise',
        startdate: 'Start Datum',
        enddate: 'End Datum',
        agents: 'zusätzlicher Agentenfilter',
        allAgents: 'alle Agenten',
        Export: 'Excel Export',
        dummy:''
      }
    },
    set: {
      agecategory: {
        title: 'Alterskategorien',
        titleCreate: 'Alterskategorie erstellen',
        titleEdit: 'Alterskategorie bearbeiten',
        description: 'Online Bezeichnung',
        help:'Einzahl/Mehrzahl (z.B. Kind/Kinder)',
        help2:'Beim Buchen und in der Bestätigung werden Kategorien mit identischer Bezeichnung zusammengefasst.',
      },
      agent: {
        title: 'Agent',
        titleCreate: 'Agent erstellen',
        titleEdit: 'Agent bearbeiten',
        id_agent: 'Agent',
        id: 'Id',
        firma: 'Organisation',
        plz_ort: 'PLZ/Ort',
        code: 'Code',
        kontakt: 'Kontakt',
        strasse: 'Adresse',
        land: 'Land',
        tel: 'Telefon',
        fax: 'Fax',
        email: 'E-Mail',
        emailtype: 'Bestätigungs-E-Mails senden an',
        bank: 'Bankname',
        postkonto: 'Postbank-Kontonummer',
        bankclearing: 'Bank Clearing-Nummer',
        kontonummer: 'Kontonummer',
        mwst: "MwST Nr.",
        error: {
          deletefailed: 'Es sind noch Verträge zu diesem Agent zugewiesen. Löschung Verweigert.',
          maxlength: 'Maximale anzahl Zeichen kann nicht grösser als %{max} sein',
          regex: 'ungültiger Code: nur Zahlen, Buchstaben und Zeichen \'_\' erlaubt',
        },
      },
      agentcontract: {
        title: 'Agentenverträge',
        titleCreate: 'Agentenverträge erstellen',
        titleEdit: 'Agentenverträge bearbeiten',
        id_agent: 'Agent',
        color: 'Farbe',
        kom_buchung: 'Kommission für Buchung',
        kom_buchung_help: '%, negative Werte: in Hauptwährung',
        kom_buchung_unit: 'Einheit',
        id_zahlungsart_react: 'Anzahlung',
        bwo: 'BookWorldOnline',
        ohne_kk: 'Buchen ohne Kreditkarte möglich',
        aufpreis: 'Kommission als Aufpreis',
        bookingfee: 'Buchungsgebühr KK',
        kom_link: 'Kommission für Link',
        kom_link_help: 'wird nur bei Reports verwendet',
        bruttopreisbuchen: 'Bruttopreis buchen',
        nettopreisbuchen: 'Nettopreis buchen',
        bwoNo: 'Kein BWO',
        bwoNR: 'Keine Raten',
        bwoMR: 'Mit Raten',
        pricePaymentSettings: 'Preis- und Zahlungseinstellungen',
        buchungAgentWebsite: 'Buchung über Website des Agenten',
        bookingChannelInternet: '(Buchungskanal: Internet)',
        buchungDurchAgent: 'Buchung durch Agent',
        bookingChannelAgent: '(Buchungskanal: Agent) [param: book]',
        error: {
          deletefailed: 'Nach dem Aufschalten einer Schnittstelle, kann der Vertrag nicht mehr gelöscht werden!',
          posReordered: 'Position neu geordert!',
          kom_buchung: 'Kommission für Buchung muss kleiner als 101 sein',
          kom_buchung_min_kom: 'Der Preisplan erfordert eine minimale Kommission von %{value}',
          kom_buchung_fix: 'Kommission für Buchung muss auf %{value} gesetzt sein',
          kom_buchung_unit: 'Die Einheit muss auf Währung stehen',
          kom_link: 'Kommission für Link muss kleiner als 100 sein',
          onTopNull: 'Kommission für Buchung muss deaktiviert sein',
          setNoRates: "BookWorldOnline muss auf 'Keine Raten' gesetzt sein",
          setZaBrutto: "Anzahlung muss auf 'Bruttopreis buchen' gesetzt sein",
          bookingfeeOn: "Buchungsgebühr KK muss aktiviert sein",
          id_agent: "Bitte Agent wählen",
        },
      },
      bed: {
        title: 'Betten',
        titleCreate: 'Bett hinzufügen',
        titleEdit: 'Bett bearbeiten',
        selectroom: 'Raum auswählen',
        errnodata: 'Bett kann nicht gelöscht werden, da es noch Buchungen enthält.',
      },
      bookingsheet: {
        title: 'Buchungsblätter',
        titleCreate: 'Buchungsblatt erstellen',
        titleEdit: 'Buchungsblatt bearbeiten',
        helptitle: '[Titel];[E-Mail-Betreff];[Markierung] (z.B.: Check-out E-Mail;Thanks for staying with us;@)',
        helppdfconf: '[only available for Supporters]<br>'+
                     'border: all[mm] <br>'+
                     'border: topbottom[mm], rightleft[mm] <br>'+
                     'boder: top[mm], right[mm], bottom[mm], left[mm] <br>'+
                     'border-left: left[mm] <br>'+
                     'papersize: <b>A4</b> | Letter | width[mm], height[mm] <br>'+
                     'textfont: courier | <b>helvetica</b> | monospace | sans | serif | times <br>',
      },
      dbuser: {
        title: 'Benutzer',
        titleCreate: 'Benutzer erstellen',
        titleEdit: 'Benutzer bearbeiten',
        active: 'Aktiv',
        activate: 'Aktivieren / Passwort setzen',
        locked: 'Gesperrt',
        unused: 'Unbenutzt',
        inactive: 'Inaktiv',
        loginname: 'Benutzername',
        name: 'Name',
        email: 'E-Mail',
        id_db_loginlevel: 'Benutzergruppe',
        id_fw_gruppe: 'Benutzergruppe Admin',
        newpwd: 'neues Passwort setzen',
        activatetitle: 'Benutzer aktivieren / Neues Passwort setzen',
        newPasswordInfo: 'Neues Passwort für Benutzer ',
        yubico: 'Yubico',
        yubicohelper: 'Wenn Sie keinen Yubico-Schlüssel haben, dieses Feld leer lassen. Sonst einfach in diesem Feld den Yubico-Schlüssel auslösen',
        error: {
          invalidChars: 'Ungültige Zeichen in Benutzername. Erlaubte Zeichen: a-z, A-Z, 0-9!',
          reactivateUser: 'Es gibt ein inaktiven Benutzer mit diesem Benutzernamen  - zum reaktiveren auf den link unterhalb clicken.',
          doubleEntry: 'Doppelter Eintrag: Dieser Benutzername existiert bereits.',
          invalidEmail: 'Ungültige E-Mail Adresse',
        },
      },
      guestcategory: {
        title: 'Gästekategorien',
        titleCreate: 'Gästekategorie erstellen',
        titleEdit: 'Gästekategorie bearbeiten',
      },
      guestdetail: {
        title: 'Gästefelder',
        titleCreate: 'Gästefeld erstellen',
        titleEdit: 'Gästefeld bearbeiten',
        options: 'Auswahl Werte',
        desc:'Bezeichnung',
      },
      hostel: {
        titleEdit: 'Einstellungen',
        property: 'Unterkunft',
        map: 'Karte',
        invoice: 'Abrechnung',
        login_name: 'Login Name',
        hostel: 'Name',
        name: 'Kontaktperson',
        cc_text: 'KK Referenz',
        strasse: 'Adresse',
        plz: 'PLZ',
        ort: 'Ort',
        land: 'Land',
        region: 'Bundesland/Kanton',
        telefon: 'Telefon',
        email: 'E-Mail',
        res_email: 'E-Mail 2',
        res_email_desc: 'Diese E-Mail-Adresse wird für Reservationsbestätigungen verwendet (BWO und Interface).',
        url: 'Website',
        mapdesc:'Die Karte verschieben um den Pin zu setzen.',
        max_dormbook: 'Anzahl DormBook',
        interfacemin: 'min Schnittstellen',
        preisplan: 'Preisplan',
        id_reseller: 'Reseller',
        id_2supporter: '2nd Level Support',
        id_supporter: 'Supporter',
        id_kundeabrechreseller: 'Rechnungsstellung',
        id_kundeabrechnung: 'Kundenabrechnung',
        setupfee: 'Setupfee charged',
        id_promotinghostel: 'Promo-Gutschrift für Hostel',
        rabatt: 'Rabatt',
        rabattart: 'Rabatt-Art',
        credituse: 'Verwendung von Credits',
        resellerremark: 'Bemerkung: von Reseller (zb zum Preis)',
        cost:'Kosten',
        monthly:'Monatliche Kosten',
        balancerep:'Kontoauszug',
        date:'Datum',
        amount:'Betrag',
        balance:'Saldo',
        config:'Konfiguration',
        timeZone:'Zeitzone',
        timeZoneInfo:'Bitte wählen Sie die Zeitzone, in der sich Ihr Hostel befindet.',
        currency0:'Hauptwährung',
        currency0Info:'Bitte ISO-Währungscodes verwenden: http://de.wikipedia.org/wiki/ISO_4217',
        currency1:'Währung 1',
        currency1Info:"muss für GOMIO-Schnittstelle auf '&#8364; %01.2f' stehen",
        currencyRate1:'Wechselkurs 1',
        currency2:'Währung 2',
        currencyRate2:'Wechselkurs 2',
        noSplitVat:'MwSt nicht Aufteilen',
        splitVat:'MwSt Aufteilen',
        splitVatInfo:'Aufteilen des Übernachtungspreise in verschiedene Teilpreise mit verschiedenen Steuersätzen',
        noVat:'Ohne Umsatzsteuer',
        normalVat:'Preise mit Umsatzsteuer',
        noVatPaid:'Assume tax of agent commission as paid',
        noVatAdd:'Tax of agent commission added to the due amount',
        noVatInfo:'Publiziert die Preise in DormOnline & Interfaces ohne Umsatzsteuer',
        pluginOccHistory:'Plugin: Verfügbarkeitesverlauf',
        pluginOccHistoryInfo:'regelmässiges speichern der Verfügbarkeiten',
        pluginOccHistoryState_0:'ausgeschaltet',
        pluginOccHistoryState_1:'aktiv',
      },
      paymenttype: {
        title: 'Zahlungsarten',
        titleCreate: 'Zahlungsart erstellen',
        titleEdit: 'Zahlungsart bearbeiten',
      },
      payrequest: {
        title: 'Zahlungspunkte',
        prozent: '%',
        fix: 'Einmalig',
        naechte: 'Nächte',
        rest: 'Rest',
        buchung: 'bei der Buchung',
        nachbuchung: 'Tage nach Buchung',
        vorankunft: 'Tage vor Ankunft',
        checkin: 'beim check-in',
      },
      prefcountry: {
        title: 'Wichtige Länder',
        titleCreate: 'Land hinzufügen',
        titleEdit: 'Land bearbeiten',
      },
      pwdyubi: {
        titleEdit: 'Passwort ändern',
        pwdtitle: 'Passwort ändern für: ',
        pwdinfo: 'Hier kann das Passwort für admin.book.world, bzw secure.dormproject.ch geändert werden.',
        pwdinfo2: 'Das Passwort für einen Benutzer der Book.World Desktop-App (DormBook) muss in der App geändert werden.',
        passwort: 'Neues Passwort',
        passwort2: 'Passwortwiederholung',
        pwdErr: 'Passwort und Wiederholung stimmen nicht überein.',
        yubititle: 'Yubico',
        yubiinfo: 'Der Yubico-Schlüssel mit der Nummer %{yubi} ist diesem Benutzer zugewiesen.',
        yubico: 'USB-Key für Zugangskontrolle',
        yubicohelp: 'Einfach in diesem Feld den Yubico-Schlüssel auslösen.',
        yubicodel: 'Yubico-Schlüssel Löschen',
      },
      rate: {
        title: 'Bezahl Optionen',
        titleEdit: 'Bezahl Optionen bearbeiten',
        cancelpoints:'Storno Bedingungen',
        actionNo:'Keine Aktion',
        actionCancel:'Reservation stornieren',
        id_payrequest: 'Zahlungspunkte',
        id_storno: 'Storno Bedingungen',
        nobwo: 'nicht Aktiv',
        bwoactive: 'Aktiv',
        bwochange: 'Online Änderung für Gäste zulassen',
      },
      room: {
        title: 'Zimmer bearbeiten',
        titleCreate: 'Zimmer hinzufügen',
        titleEdit: 'Zimmer bearbeiten',
        editBeds: 'Betten bearbeiten',
        bedcount: 'Anzahl Betten',
        errnodata: 'Zimmer kann nicht gelöscht werden, da es noch Betten enthält.',
        bezeichnungInfo: 'Für jede Zeile in diesem Feld wird ein Zimmer angelegt mit den unten eingestellten Parametern.',
        dokuLink: '<a href="https://docs.dormproject.ch/de/konfiguration---dormonline/zimmer/zimmer-online.html" target="_blank" rel="noopener noreferrer" >Dokumentation</a>',
      },
      roomcategory: {
        title: 'Zimmer Kategorie',
        titleCreate: 'Zimmer Kategorie hinzufügen',
        titleEdit: 'Zimmer Kategorie editieren',
        rooms: 'Zugeordnete Zimmer',

        in_bfs: 'Stat',
        in_daily_list: 'Tag',
        in_report: 'Rep',
        room_cnt: 'Zimmer',

        dormbookSetting: 'DormBook Einstellung',
        dormonlineSetting: 'DormOnline Einstellung',
        reportSetting: 'Report Einstellung',
        units: 'Einheiten',
        units_info: 'Mehrbett- und Privatzimmer (Mehrbett einzahl/Mehrzahl/Privat Einzahl/Mehrzahl)',
        bwoSetting: 'BookWorldOnline Einstellung',
        roomSetting: 'Zimmer Einstellung',

        errRoomNotEmpty: "Zimmerkategorie kann nicht gelöscht werden, da noch Zimmer dazu gehören. Zuerst bei den Zimmern die Zimmerkategorie neu zuordnen.",
      },
      roomcattext: {
        title: 'Texte für ',
        textBWO: 'BWO Zimmerkategorie Texte',
      },
      service: {
        title: 'Zusatzleistungen',
        titleCreate: 'Zusatzleistung hinzufügen',
        titleEdit: 'Zusatzleistung bearbeiten',
        anz_modifier: 'Bedingung',
        anz_modifier_comment: "Trenne die Werte mit | oder Newline<br><b>Optionen/Beispiel:</b><br>LimitNightsMax=7<br>MinNights=8<br>MaxNights=2<br>ConnectedRoomtypes={id},{id}<br>ConnectedAgecategories={id},{id}<br>ConnectedAgent={id},{id}<br>ChildService={id},{id}<br>UnsetService={id},{id}",
      },
      servicetext: {
        title: 'Texte für ',
        textBWO: 'BWO Zusatzleistung Texte',
      },
      specialday: {
        title: 'Spezielle Tage',
        titleCreate: 'Tag Hinzufügen',
        titleEdit: 'Tag Bearbeiten',
        text: 'Online Text',
        searchDate:'Daum filtern',
        searchDesc:'Nach Beschreibung suchen',
        copytitle: 'Copy date information',
        copyinfo: 'Die Einstellungen des ausgewählten Datums sollen auf alle Tage zwischen dem ersten und letzten Datum kopiert werden.',
        overwrite: 'Bereits gesetzte Werte werden ohne Rückfrage überschrieben.',
        errnodata: 'Datum noch nicht definiert. Bitte zuerst unter «Kalender» eröffnen.',
      },
    },
  },
  glob: {
    actualPicture: 'aktuelles Bild',
    confirm: 'Bestätigen',
    copy: 'Kopieren',
    imgremove: 'entfernen',
    close: 'Schliessen',
    move: 'Verschieben',
    activate: 'Aktivieren',
    uploadPicture: 'Bild hochladen',
    columns: 'Spalten',
    day: {
      short: {
        mon: 'Mo',
        tue: 'Di',
        wed: 'Mi',
        thu: 'Do',
        fri: 'Fr',
        sat: 'Sa',
        sun: 'So',
      },
    },
    deftypes:{
      one: '* 1',
      num_nights: '* Anzahl Nächte',
      num_person: '* Anzahl Personen',
      num_night_person: '* Anzahl Nächte * Anzahl Personen',
      net_price: '* Nettobetrag (Übernachtung)',
      price_per_night: '* Preis pro Nacht (Durchschnitt)',
    },
    dormbook:{
      not_accessible: 'nicht Verfügbar',
      only_pdf: 'nur PDF-Dokument',
      only_email: 'nur E-Mail-Versand',
      email_and_pdf: 'E-Mail-Versand und PDF-Dokument',
    },
    dormbookdata:{
      per_group: '1x pro Reservationsgruppe',
      per_reservation: '1x pro Reservation',
      per_guest: '1x pro Gast',
    },
    error:{
      wrongHostel: 'Falsches Hostel',
      noAccess: 'Kein Zugang',
      dbError: 'Datenbank konnte nicht beschrieben werden',
    },
    gender:{
      label: 'Geschlecht',
      woman: 'Frau',
      man: 'Mann',
      mixed: 'Gemischt',
      empty: 'Leer',
      private: 'Privat',
    },
    language:{
      de: 'Deutsch',
      en: 'Englisch',
      fr: 'Französisch',
    },
    agentEmailType:{
      guest: '(0) Gast',
      guest_agent: '(1) Gast + Agent',
      agent: '(2) Agent',
    },
    onlinechoices:{
      not_online: 'Nicht online',
      cons_book_woman: 'Reservationen beachten, Frauen',
      cons_book_man: 'Reservationen beachten, Männer',
      cons_book_woman_man: 'Reservationen beachten, Frauen oder Männer',
      cons_book_mixed: 'Reservationen beachten, Gemischt',
      cons_book_woman_mixed: 'Reservationen beachten, Frauen oder Gemischt',
      cons_book_man_mixed: 'Reservationen beachten, Männer oder Gemischt',
      cons_book_all: 'Reservationen beachten, Alle Möglichkeiten',
      ign_book_woman: 'Reservationen nicht beachten, Frauen',
      ign_book_man: 'Reservationen nicht beachten, Männer',
      ign_book_mixed: 'Reservationen nicht beachten, Gemischt',
    },
    onlinetypes:{
      not_online: 'Nicht Online',
      checkbox: 'Ja/Nein',
      num_selectable: 'Anzahl wählbar',
      fix: 'Fix',
    },
    resstatus:{
      stat0: 'Normal',
      stat1: 'Provisorisch',
      stat2: 'Fix',
      stat3: 'Eingecheckt',
      stat4: 'no Show',
      stat5: 'Storniert',
      stat6: 'Ausgecheckt',
    },
    servermsg:{
      paybill: 'Ihre Zahlung für die offene(n) Rechnung(en) ist noch nicht eingegangen.',
      accountcancelledNotPaid: 'Ihre Zahlung für die Abschlussrechnung ist noch nicht eingegangen. Dieses Konto wurde gekündigt. Bitte führen Sie alle erforderlichen Reporte vor dem Kündiungsdatum aus. Danach werden wir Ihre Daten gemäss unseren internen Richtlinien archivieren/löschen.',
      accountcancelled: 'Dieses Konto wurde gekündigt. Bitte führen Sie alle erforderlichen Reporte vor dem Kündiungsdatum aus. Danach werden wir Ihre Daten gemäss unseren internen Richtlinien archivieren/löschen.',
    },
    pwdremark: '-> Bitte dieses Passwort aufschreiben!',
    pwdremark2: 'Wichtig: Dieses Password muss beim ersten einloggen geändert werden!',
    pwdcopied: 'Das Passwort wurde kopiert',
  },
  resources: {
    bwhAgent: {
      fields: {
        q: 'Suche',
      },
    },
    bwhDiscount: {
      name: 'Rabatt Code |||| Rabatt Codes',
      forcedCaseName: 'Rabatt Code |||| Rabatt Codes',
      fields: {
        rabatt: 'Rabatt',
        rabatt_typ: 'Rabatttyp',
        status: 'Status',
        ankunft_min: 'Anfkunft ab',
        ankunft_max: 'Ankunft bis',
        personen_min: 'Min. Personen',
        personen_max: 'Max. Personen',
        dauer_min: 'Min. Dauer ',
        dauer_max: 'Max. Dauer',
        abgelaufen: 'Abgelaufene Codes',
      },
    },
    bwhGuestfield: {
      fields: {
        q: 'Suche',
        orig_text: 'Originaltext',
        disp_opts: 'Anzeigeoptionen',
        checkin: 'Checkin',
        validate: 'Prüfung',
      }
    },
    bwhImage: {
      name: 'Bild |||| Bilder',
      forcedCaseName: 'Bild |||| Bilder',
      fields: {
        art_lbl: 'Bildtyp',
        art_lbl_tr: 'Bildtyp',
        text: 'Bild',
        id_element: 'Position',
      },
    },
    bwhRoomcategory: {
      fields: {
        bezeichnung: 'Kategorie',
        img: 'Bild',
        id_element: 'Position',
        not_onbwh: 'Nicht Online',
      },
    },
    bwhService: {
      fields: {
        bezeichnung: 'Service',
        img: 'Bild',
        not_onbwh: 'Nicht Online',
      },
    },
    bwhSettings: {
      fields: {
        bwh_status: 'Status',
        bwh_defaultsprache: 'Standardsprache',
        bwh_sprachen: 'Übesetzte Sprachen',
        bwh_ident: 'Domain Präfix',
        bwh_domain: 'Domain',
      },
    },
    bwhText: {
      name: 'Text |||| Texte',
      forcedCaseName: 'Text |||| Text',
      fields: {
        art_lbl: 'Textart',
        id_agent: 'Agent',
        id_element: 'Position',
      },
    },
    calPricegr: {
      name: 'Preisgruppe |||| Preisgruppen',
      forcedCaseName: 'Preisgruppe |||| Preisgruppen',
      fields: {
        q: 'Suche',
        bezeichnung: 'Bezeichnung',
        mwst: 'MwSt',
        preiskat: 'Zimmerkategorie',
        alterskat: 'Alterskategorie',
        belegung: 'Belegung',
        preis: 'Preis (Person)',
        preisRoom: 'Preis (Zimmer)',
        pricezero: 'Preis = 0',
      },
    },
    calOnline: {
      name: 'Online Gruppe |||| Online Gruppen',
      forcedCaseName: 'Online Gruppe |||| Online Gruppen',
      fields: {
        q: 'Suche',
        bezeichnung: 'Bezeichnung',
        farbe: 'Farbe',
        copyTitle: 'Neuer Gruppen-Name',
      },
    },
    calOportal: {
      fields: {
        firma: 'Portal',
        kontingent: 'Kont.',
        freihalten: 'Freih.',
        id_preiskat: 'Zimmerkategorie',
        min_nachtA: 'Min',
        max_nachtA: 'Max',
        noarrivalwd: 'k.Ank.',
        nodeparturewd: 'k.Abr.',
        firstres: 'Erst',
        lastnewres: 'Neu',
        lastchange: 'LM Change',
        lastminstart: 'LM Start',
        lastminend: 'LM End',
        zimmer: 'Zimmer',
      },
    },
    calService: {
      name: 'Zusatzeistung |||| Zusatzeistungen',
      forcedCaseName: 'Zusatzeistung |||| Zusatzeistungen',
      fields: {
        q: 'Suche',
        bezeichnung: 'Bezeichnung',
        farbe: 'Farbe',
        copyTitle: 'Neuer Gruppen-Name',
        zusatzleistung: 'Zusatzeistung',
        preis: 'Preis',
        mwst: 'U St/MwSt',
        online_type: 'Online',
      },
    },
    globHostel: {
      fields: {
        q: 'Suchen',
        hostel_id: 'Hostel ID',
        hostel: 'Hostel',
        cc_text: 'KK-Referenz',
        name: 'Kontaktperson',
        strasse: 'Adresse',
        ort: 'Ort',
        land: 'Land',
        region: 'Bundesland/Kanton',
        latitude: 'Längengrad',
        longitude: 'Breitengrad',
        telefon: 'Telefon',
        fax: 'Fax',
        email: 'E-Mail',
        res_email: 'E-Mail 2',
        url: 'URL',
        titel: 'URL',
        text_de: 'Text Deutsch',
        text_en: 'Text Englisch',
        text_fr: 'Text Französisch',
        max_dormbook: 'Anzahl DormBook',
        Preisplan: 'Preisplan',
        interfacemin: 'min invoiced interfaces',
        id_reseller: 'Reseller',
        id_kundeabrechreseller: 'Rechnungsstellung',
        id_kundeabrechnung: 'Kundenabrechnung',
        id_promotinghostel: 'Promo-Gutschrift für Hostel',
        rabatt: 'Rabatt',
        rabattart: 'Rabatt-Art',
        credituse: 'Verwendung von Credits',
        resellerremark: 'Bemerkung: von Reseller (zb zum Preis)',
        guestdetail: 'Gast-Details kopieren',
        alterskat: 'Alterskategorien kopieren',
        zahlungsart: 'Zahlungsarten kopieren',
        zusatzleistung: 'Zusatzleistungen kopieren',
      },
    },
    repDocuments: {
      fields: {
        datum: 'Datum',
        type: 'Typ',
        flag: 'Neu',
        titel: 'Titel',
        filename: 'Dateiname',
      },
    },
    repMessages: {
      fields: {
        betreff: 'Betreff',
        id_hostel: 'Hostel',
        receiver: 'Empfänger',
        type: 'Typ',
        zeit: 'Datum',
      },
    },
    setAgecategory: {
      empty: 'Noch keine Alterskategorie erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
        abk: 'Abkürzung',
      },
    },
    setAgent: {
      name: 'Agent |||| Agenten',
      forcedCaseName: 'Agent |||| Agenten',
      empty: 'Noch keine Agenten.',
      fields: {
        q: 'Suche',
        id: 'Id',
        firma: 'Organisation',
        plz_ort: 'Ort',
        code: 'Code',
        kontakt: 'Kontakt',
        strasse: 'Strasse',
        land: 'Land',
        tel: 'Tel',
        fax: 'Fax',
        email: 'Email',
        emailtype: 'Email Typ',
        bank: 'Bank',
        postkonto: 'Postkonto',
        bankclearing: 'Bankclearing',
        kontonummer: 'Kontonummer',
        mwst: "MwST",
      },
    },
    setAgentcontract: {
      name: 'Agenten Vertrag |||| Agenten Verträge',
      forcedCaseName: 'Agenten Vertrag |||| Agenten Verträge',
      empty: 'Noch keine Verträge',
      fields: {
        q: 'Suche',
        id: 'Id',
        pos: 'Pos',
        firma: 'Organisation',
        plz_ort: 'Ort',
        kom_buchung: 'Book',
        aufpreis: 'on Top',
        bookingfee: 'Fee',
        zart: 'Type',
        ohne_kk: 'no CC',
        kom_link: 'Link',
      },
    },
    setBed: {
      name: 'Bett |||| Betten',
      forcedCaseName: 'Bett |||| Betten',
      empty: 'Noch kein Bett erfasst',
      invite: 'Ein erstes erstellen?',
      fields: {
        id_zimmer: 'Zimmer',
        buchstabe: 'Bett',
        matratze: 'Zusatzbett',
        bemerkung: 'Bemerkung',
      },
    },
    setBookingsheet: {
      empty: 'Noch kein Buchungsblatt erfasst',
      invite: 'Ein erstes erstellen?',
      fields: {
        titel: 'Titel',
        id_sprache: 'Sprache',
        pdfconf: 'PDF Conf',
        dormbook: 'DormBook',
        data: 'Daten',
        id_datei: 'docx-Datei',
        html: 'HTML',
        text: 'Text',
      },
    },
    setDbuser: {
      name: 'Benutzer',
      forcedCaseName: 'Benutzer',
      empty: 'Keine Benutzer gefunden.',
      fields: {
        q: 'Suche',
        loginname: 'Benutzername',
        name: 'Name',
        email: 'E-Mail',
        id_db_loginlevel: 'Benutzergruppe',
        id_fw_gruppe: 'Benutzergruppen Admin',
        status: 'Status',
        yubi: 'Yubico',
        hid: 'HID',
        inactive: '(inaktiv)',
        last_change: '(change)',
        edit_en: '',
      },
    },
    setDbuserglob: {
      name: 'Benutzer',
      forcedCaseName: 'Benutzer',
      empty: 'Keine Benutzer gefunden.',
      fields: {
        q: 'Suche',
        loginname: 'Benutzername',
        name: 'Name',
        email: 'E-Mail',
        id_db_loginlevel: 'Benutzergruppe',
        id_fw_gruppe: 'Benutzergruppen Admin',
        status: 'Status',
        yubi: 'Yubico',
        hid: 'HID',
        inactive: '(inaktiv)',
        last_change: '(change)',
        edit_en: '',
      },
    },
    setGuestcategory: {
      name: 'Gästekategorie |||| Gästekategorien',
      forcedCaseName: 'Gästekategorie |||| Gästekategorien',
      empty: 'Noch keine Gästekategorie erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
      },
    },
    setGuestdetail: {
      empty: 'Noch keine Gastdefelder erfasst',
      invite: 'Ein erstes erstellen?',
      fields: {
        title_de: 'Titel Deutsch',
        title_en: 'Titel Englisch',
        bez1_de: 'Feld 1 Deutsch',
        bez1_en: 'Feld 1 Englisch',
        bez2_de: 'Feld 2 Deutsch',
        bez2_en: 'Feld 2 Englisch',
        bez3_de: 'Feld 3 Deutsch',
        bez3_en: 'Feld 3 Englisch',
        bez4_de: 'Feld 4 Deutsch',
        bez4_en: 'Feld 4 Englisch',
        bez5_de: 'Feld 5 Deutsch',
        bez5_en: 'Feld 5 Englisch',
        bez6_de: 'Feld 6 Deutsch',
        bez6_en: 'Feld 6 Englisch',
      },
    },
    setHostel: {
      fields: {
      },
    },
    setPaymenttype: {
      empty: 'Noch keine Zahlungsart erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
      },
    },
    setPayrequest: {
      name: 'Zahlungspunkt |||| Zahlungspunkte',
      forcedCaseName : 'Zahlungspunkt |||| Zahlungspunkte',
      empty: 'Noch kein Zahlungspunkt erfasst',
      invite: 'Einen ersten erstellen?',
      fields: {
        zeit: 'Zeit',
        zeitart: 'Zeitreferenz',
        betrag: 'Wert',
        betragart: 'Einheit',
        active: 'aktiv',
      },
    },
    setPrefcountry: {
      name: 'Hauptland |||| Hauptländer',
      forcedCaseName : 'Hauptland |||| Hauptländer',
      empty: 'Noch kein Top Land erfasst',
      invite: 'Ein erstes erstellen?',
      fields: {
        id_land: 'Land',
      },
    },
    setRate: {
      name: 'Rate |||| Raten',
      forcedCaseName : 'Rate |||| Raten',
      empty: 'Noch keine Rate erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
        id: 'Zahlungspunkte',
        erinnerung: 'Erinnerung',
        frist: 'Frist',
        aktion: 'Aktion',
        id_payrequest: 'Zahlungspunkte',
        id_storno: 'Storno Bedingungen',
        min_stay: 'Min Nächte',
        max_stay: 'Max Nächte',
        min_pers: 'Min Pers.',
        max_pers: 'Max Pers.',
        bwo_rate: 'BookWorldOnline',
        default_rate: 'Standard Option',
      },
    },
    setRoom: {
      name: 'Zimmer',
      forcedCaseName: 'Zimmer',
      empty: 'Noch kein Zimmer erfasst',
      invite: 'Ein erstes erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
        id_preiskat: 'Zimmerkategorie',
        geschlecht: 'Geschlecht',
        bett: 'Bett',
        zusatz: 'Zusatzbett',
        online: 'Online',
        pos: 'Pos',
        farbe: 'Farbe',
        id: 'ID',
      },
    },
    setRoomcategory: {
      name: 'Zimmerkategorie |||| Zimmerkategorien',
      forcedCaseName: 'Zimmerkategorie |||| Zimmerkategorien',
      empty: 'Noch keine Zimmerkategorie erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        q: 'Suche',
        bezeichnung: 'Bezeichnung',
        kurzbez: 'Kürzel (4 Zeichen)',
        farbe: 'Farbe',
        text_de: 'Text Deutsch',
        text_en: 'Text Englisch',
        text_fr: 'Text Französisch',
        bild: 'Bild',
        stattagrep: 's/d/r',
        in_bfs: 'In Statistik',
        in_daily_list: 'In Tageslisten',
        in_report: 'In Reporten',
        id: 'ID',
      },
    },
    setService: {
      name: 'Zusatzleistung |||| Zusatzleistungen',
      forcedCaseName: 'Zusatzleistung |||| Zusatzleistungen',
      empty: 'Noch keine Zusatzleistung erfasst',
      invite: 'Eine erste erstellen?',
      fields: {
        bezeichnung: 'Bezeichnung',
        bild: 'Bild',
        kuerzel: 'Kürzel',
        def_anz: 'Standard-Anzahl',
        def_type: 'Standard-Typ',
        online_type: 'Online',
        text_de: 'Text Deutsch',
        text_en: 'Text Englisch',
        text_fr: 'Text Französisch',
        pos: 'Pos',
        anz_modifier: 'Bedingung',
      },
    },
    setSpecialday: {
      empty: 'Noch keine Spezialtage erfasst',
      invite: 'Einen ersten erstellen?',
      fields: {
        datum: 'Datum',
        bemerkung: 'Bemerkung',
      },
    },
  },
}
export default langDE;
