import React, { useState, useEffect } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, useRedirect, Edit, SimpleForm,
        BooleanInput, SelectInput,
        useRefresh, useNotify, SelectArrayInput, 
        Button } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import CloseToolbar from '../../lib/CloseToolbar';
import dataProvider from '../../lib/dataProvider';
import AddIcon from 'mdi-react/AddIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import { useTheme } from '@mui/material/styles';

const belChoices=[]; for (var i=0; i<=20; i++) belChoices.push({id:i, name:''+i});

const asyncLoad = async (resource, setList, ids) => {
  dataProvider.getList(resource, {
      id: 0,
      filter: { },
      sort: {  },
      pagination: { page: 1, perPage: 999 },
    })
    .then(response => {
      if (response.data) {
        const data = response.data;
        var arr = [];
        var obj = {};
        var filtered = [];
        for(var i = 0; i < data.length; i++) {
          arr.push({id: data[i].id, name: data[i].bezeichnung});
          obj[data[i].id] = data[i].bezeichnung;
          if (ids && ids.indexOf(data[i].id) > -1) {
            filtered.push({id: data[i].id, name: data[i].bezeichnung});
          }
        }
        setList({arr:arr, obj:obj, filtered:filtered});
      }
    });
};

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, permissions, ...props}) => {
  const record = useRecordContext();

  const [dload, setLoading] = useState(false);
  const [aclist, setAclist] = useState(false);
  const [rclist, setRclist] = useState(false);
  const [filter, setFilter] = useState(false);
  const [checkCnt, setCheckCnt] = useState([]);
  const [filteron, setFilterOn] = useState(false);
  const [checks, setChecks] = useState(false);
  const classes = {} // useSelectStyles();
  const translate = useTranslate();
  const theme = useTheme();

  const SimpleNumInput = ({name, disabled, mutationMode, helperText, ...props}) => {
    const inp = useController({ name, defaultValue: '' });
    return (
      <input {...inp.field} type="number" style={{border: 'none', padding:'5px', width:'100px',textAlign:'right', backgroundColor:disabled?'#FFF':'#EEE' }} />
    )        
  }                                                                         
  const SimpleCheckbox = ({name, i, ...props}) => {
    const { setValue } = useFormContext();
    return (
      <input type="checkbox" checked={checks[i]} 
        onChange={e=> { 
          checks[i] = !checks[i];
          setChecks(checks); 
          setCheckCnt(checkCnt+1); 
          setValue(name, checks[i], { shouldDirty: true });
        }} 
        style={{border: 'none', padding:'5px', width:'20px',textAlign:'right' }} 
      />
    )        
  }
  const SimpleSelectInput = ({ ...props}) => {
    if(!props.choices) return null;
    return (
      <SelectInput {...props} label="" helperText={false} />
    )
  }
  const Cell = ({ saving, undoable, save, type, mutationMode, ...props}) => {
    const {classes, translate, label, optionText, validate, ...rest} = props;
    switch(type) {
      case 'value': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc', textDecoration:props.disabled?'line-through':'none'}}>{props.value}</td>)
      case 'num': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleNumInput {...props} label="" helperText={false} /></td>)
      case 'numhidden': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><div style={{visibility:'hidden'}}><SimpleNumInput {...props} label="" helperText={false}/></div></td>)
      case 'check': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleCheckbox {...props} /></td>)
      case 'select': return (<td style={{padding: '0px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleSelectInput {...props} /></td>)
      default: return null;
    }
  }

  const AddPriceButton = ({rcarr, acarr, ...props}) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    if(!rcarr || !acarr)return null;
    const handleClick = () => {
      setShowDialog(true);
    };
    const handleCloseClick = () => {
      setShowDialog(false);
    };
    const doSave = (data) => {
      dataProvider.update('calPricegr', { id: record.id, data:data })
        .then(({ data }) => {
          setShowDialog(false);
          refresh();
          redirect(`/calPrice/${record.id}`)
          setChecks(false);
        })
        .catch(error => {
          notify(error.message, 'error');
        })
    };       
    if (rcarr[0] && rcarr[0].id !== -1) {
      rcarr.unshift({id:-1,name:'All'});
    }
    if (acarr[0] && acarr[0].id !== -1) {
      acarr.unshift({id:-1,name:'All'});
    }
    return (
      <React.Fragment>
        <Button onClick={handleClick} label="bw.cal.pricegr.titleCreate">
          <AddIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.pricegr.titleCreate" >
          <DialogTitle>{translate("bw.cal.pricegr.titleCreate")}</DialogTitle>
          <DialogContent>
            <SimpleForm resource="calPricegr" onSubmit={doSave} >
              <React.Fragment>
                <div>{translate('bw.cal.pricegr.createInfo')}</div>
              </React.Fragment>
              <SelectArrayInput source="id_preiskat_add" choices={rcarr} label='resources.calPricegr.fields.preiskat' />
              <SelectArrayInput source="id_alterskat_add" choices={acarr} label='resources.calPricegr.fields.alterskat' />
              <SelectArrayInput source="belegung_add" choices={belChoices} label='resources.calPricegr.fields.belegung' />
            </SimpleForm>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  };
  
  const PriceRows = () => {
    const { getValues, setValue, watch } = useFormContext();
    useEffect(() => {
      for(i=0; i<record.tpreis_anz; i++) {
        setValue("tpreis."+i+".preisRoom", Math.round(10000*getValues("tpreis."+i+".belegung") * getValues("tpreis."+i+".preis"))/10000 )
      }
    }, []);

    var rows = [];
    if(rclist.arr && aclist.arr){
      var show = true;
      for(i=0; i<record.tpreis_anz; i++) {
        show = true;
        if(filter){                            
          if(filter.rc && filter.rc !== record.tpreis[i].id_preiskat)show = false;
          if(filter.ac && filter.ac !== record.tpreis[i].id_alterskat)show = false;
          if(filter.bc && filter.bc !== record.tpreis[i].belegung)show = false;
          if(filter.pr && record.tpreis[i].preis > 0)show = false;
        }
        if(show){
          watch("tpreis."+i+".belegung");
          rows.push(
            <tr key={i}>
              <Cell type="check" name={"tpreis."+i+".addsub"} i={i} />
              <Cell type="value" value={rclist.obj[record.tpreis[i].id_preiskat]} disabled={!checks[i]}/>
              <Cell type="value" value={aclist.obj[record.tpreis[i].id_alterskat]} disabled={!checks[i]}/>
              <Cell type="num" name={"tpreis."+i+".belegung"} className={classes.padding2} disabled={!checks[i]} {...props} 
                onChange={(e)=>{setValue(e.target.name.replace('.belegung', '.preisRoom'), 
                  Math.round(10000*getValues(e.target.name) * getValues(e.target.name.replace('.belegung', '.preis')))/10000 )}} />
              <Cell type="num" name={"tpreis."+i+".preis"} className={classes.padding2} disabled={!checks[i]} {...props} 
                onChange={(e)=>{setValue(e.target.name+"Room", 
                  Math.round(10000*getValues(e.target.name) * getValues(e.target.name.replace('.preis', '.belegung')))/10000 )}} />
              <Cell type={getValues("tpreis."+i+".belegung")>0?"num":"numhidden"} name={"tpreis."+i+".preisRoom"} className={classes.padding2} disabled={!checks[i]} {...props}
                onChange={(e)=>{setValue(e.target.name.replace('.preisRoom', '.preis'), 
                  Math.round(100000*getValues(e.target.name) / Math.max(1, getValues(e.target.name.replace('.preisRoom', '.belegung'))))/100000)}} />
              {isHauptadmin() &&
                <Cell type="value" value={record.tpreis[i].id} disabled={!checks[i]}/>
              }
            </tr>
          )
        }
      }
    }
    return rows;
  }


  if(record.id && record.tpreis_anz !== dload){
    const rcIds = [];
    const acIds = [];
    for(var i=0; i<record.tpreis_anz; i++){
      rcIds.push(record.tpreis[i].id_preiskat);
      acIds.push(record.tpreis[i].id_alterskat);
    }
    const rcIdsUnique = [ ...new Set(rcIds)];
    const acIdsUnique = [ ...new Set(acIds)];
    asyncLoad('setRoomcategory', setRclist, rcIdsUnique);
    asyncLoad('setAgecategory', setAclist, acIdsUnique);
    setLoading(record.tpreis_anz);
    setChecks(false);
  }

  var belfilter = [];
  if(rclist.arr && aclist.arr){
    if (checks===false && record.tpreis_anz) {
      var chk = {};
      for(i=0; i<record.tpreis_anz; i++) {
        chk[i] = record.tpreis[i].addsub;
      }
      setChecks(chk);
      setFilterOn(true);
    }
    for(i=0; i<record.tpreis_anz; i++) {
      belfilter[record.tpreis[i].belegung] = record.tpreis[i].belegung;
    }
    belfilter.sort();
  }
  const belfdata = belfilter.map((v, i)=>{return{id:v,name:v}});
  return (
    <SimpleForm toolbar={<CloseToolbar redir="/calPricegr/" />} {...props} >
      <div><h2 style={{backgroundColor:record.farbe,color:record.textfarbe,padding:'0.2em', borderRadius: '4px'}}>{record.bezeichnung}</h2></div>
      { filteron &&
        <div style={{ display: 'flex', flexWrap: 'wrap', width: 'auto', backgroundColor: theme.palette.mode==='light'?'#F5F5F5':'#303030', paddingLeft: '4em' }} className={classes.flex} >
          <SelectInput source={"rcfilter"} record={filter.rc} choices={rclist.filtered} emptyText="bw.cal.pricegr.showall" label="resources.calPricegr.fields.preiskat" helperText={false} onChange={e=> { setFilter({...filter, ...{rc: e.target.value}}) }} />
          <SelectInput source={"acfilter"} record={filter.ac} choices={aclist.filtered} emptyText="bw.cal.pricegr.showall" label="resources.calPricegr.fields.alterskat" helperText={false} onChange={e=> { setFilter({...filter, ...{ac: e.target.value}}) }} />
          <SelectInput source={"belfilter"} record={filter.bc} choices={belfdata} emptyText="bw.cal.pricegr.showall" label="resources.calPricegr.fields.belegung" helperText={false} translateChoice={false} onChange={e=> { setFilter({...filter, ...{bc: e.target.value}}) }} />
          <BooleanInput className={classes.bool} source={"pricefilter"} record={filter.pr} label="resources.calPricegr.fields.pricezero" helperText={false} onChange={e=> { setFilter({...filter, ...{pr: e.target.checked}}) }} />
        </div>
      }
      <Table style={{width:'auto', borderCollapse:'collapse', padding:0}} >
        <tbody>
          <tr align="left">
            <th></th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>{translate('resources.calPricegr.fields.preiskat')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>{translate('resources.calPricegr.fields.alterskat')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>{translate('resources.calPricegr.fields.belegung')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>{translate('resources.calPricegr.fields.preis')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>{translate('resources.calPricegr.fields.preisRoom')}</th>
            {isHauptadmin() &&
              <th style={{padding: '5px 1em', borderBottom:'1px solid #333', width:'200px'}}>id</th>
            }
          </tr>
          <PriceRows />
        </tbody>
      </Table>
      <AddPriceButton acarr={aclist.arr} rcarr={rclist.arr} setChecks={setChecks}/>
    </SimpleForm>
  )
};

const EditPreis = ({ ...props }) => (
  <Edit title="bw.cal.pricegr.titleEdit" mutationMode="pessimistic" {...props} redirect="/calPricegr/" >
    <CEForm {...props} />
  </Edit>
)

export default EditPreis;