import React from 'react';
import { Admin, defaultTheme, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import dataProvider from './lib/dataProvider';
import authProvider from './lib/authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';
import MyLayout from './lib/MyLayout';
import Login from './lib/Login.tsx';
import Dashboard from './routes/dashboard';
import Infoarchiv from './routes/infoarchiv';
import Ereignisarchiv from './routes/ereignisarchiv';
import ereignisedit from './routes/ereignisedit';

import englishMessages from 'ra-language-english';
import languageDe from './language-de';
import languageEn from './language-en';

import {MenuConext} from './lib/MenuContext';

import catchAll from './routes/catchAll'

import documents from './routes/rep/documents'
import repMessages from './routes/rep/messages'

import bwhsettings from './routes/bwh/settings'
import bwhagent from './routes/bwh/agent'
import bwhtext from './routes/bwh/text'
import bwhimage from './routes/bwh/image'
import bwhroomcategory from './routes/bwh/roomcategory'
import bwhservice from './routes/bwh/service'
import bwhguestfield from './routes/bwh/guestfield'
import discount from './routes/bwh/discount'

import sethostel from './routes/set/hostel'
import rate from './routes/set/rate'
import payrequest from './routes/set/payrequest'
import paymenttype from './routes/set/paymenttype'
import agecategory from './routes/set/agecategory'
import setservice from './routes/set/service'
import guestcategory from './routes/set/guestcategory'
import guestdetail from './routes/set/guestdetail'
import prefcountry from './routes/set/prefcountry'
import specialday from './routes/set/specialday'
import room from './routes/set/room'
import bed from './routes/set/bed'
import roomcategory from './routes/set/roomcategory'
import setagentcontract from './routes/set/agentcontract'
import setagent from './routes/set/agent'
import setappopt from './routes/set/appopt'
import setpwdyubi from './routes/set/pwdyubi'
import setdbuser from './routes/set/dbuser'
import setroomcattext from './routes/set/roomcattext'
import setservicetext from './routes/set/servicetext'

import globuser from './routes/glob/user'
import globhostel from './routes/glob/hostel'
import tlogapi from './routes/glob/tlogapi'

import calpricegr from './routes/cal/pricegr'
import calpreis from './routes/cal/preis'
import calpricegrcompare from './routes/cal/pricegrcompare'
import calpricecomparerate from './routes/cal/pricegrcomparerate'
import pricegrcomparevat from './routes/cal/pricegrcomparevat'
import calmwst from './routes/cal/mwst'
import calrate from './routes/cal/rate'
import calonline from './routes/cal/online'
import calonlinecompare from './routes/cal/onlinecompare'
import caloportal from './routes/cal/oportal'
import calservice from './routes/cal/service'


import Contact from './routes/contact'
import Profil from './routes/profil';
import SetTransfer from './routes/set/transfer';
import YieldEdit from './routes/cal/yield'
import CalendarEdit from './routes/cal/calendar'
import GlobServer from './routes/glob/server'
import RepBookingcom from './routes/rep/bookingcom'
import RepGdpr from './routes/rep/gdpr'
import RepGuestexport from './routes/rep/guestexport'
import IfPortal from './routes/if/portal'
import IfList from './routes/if/list'

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.updateMain = (newMain) => {
      this.setState(state => ({
        activeMain: newMain,
      }));
      localStorage.setItem('lastmenu', newMain);
    };

    this.state = {
      activeMain: -1,
      updateMain: this.updateMain,
    };
  }
  render() {
    const i18nProvider = polyglotI18nProvider(locale => {
      var texts = locale==='de'?{...languageEn, ...germanMessages, ...languageDe}:{...englishMessages,  ...languageEn};
      if (locale==='de') {
        texts.ra.notification.deleted = 'Element wird gelöscht |||| %{smart_count} Elemente werden gelöscht';
        texts.ra.action.select_columns = 'Spalten';
        texts.ra.configurable = {customize: 'Hallo'};
      }
      else {
        texts.ra.notification.deleted = 'Deleting element |||| Deleting %{smart_count} elements';
        texts.ra.action.select_columns = 'Columns';
      }
      return texts;
    });

    
    const theme = {
        ...defaultTheme,
        palette: {
            primary: {
              main: '#3f51b5',
            }
        },
    };    
    return (
      <MenuConext.Provider value={this.state}>
        <Admin
          i18nProvider={i18nProvider}
          catchAll={catchAll}
          authProvider={authProvider}
          layout={MyLayout}
          loginPage={Login}
          dataProvider={dataProvider}
          dashboard={Dashboard}
          theme={theme}
        >
          <Resource name="dashboardHomeinfo" {...ereignisedit} />

          <Resource name="repBookingcom" />
          <Resource name="repGdpr" />
          <Resource name="repDocuments" {...documents} />
          <Resource name="repGuestexport" />
          <Resource name="repMessages" {...repMessages} />

          <Resource name="bwhSettings" {...bwhsettings} />
          <Resource name="bwhAgent" {...bwhagent} />
          <Resource name="bwhDiscount" {...discount} />
          <Resource name="bwhText" {...bwhtext} />
          <Resource name="bwhTextagent"  />
          <Resource name="bwhImage" {...bwhimage} />
          <Resource name="bwhIcon" />
          <Resource name="bwhRoomcategory" {...bwhroomcategory} />
          <Resource name="bwhService" {...bwhservice} />
          <Resource name="bwhGuestfield" {...bwhguestfield} />

          <Resource name="setHostel" {...sethostel} />
          <Resource name="setCurrency" />
          <Resource name="setTimezone" />
          <Resource name="setRoomcattext" {...setroomcattext} />
          <Resource name="setServicetext" {...setservicetext} />
          <Resource name="setService" {...setservice} />
          <Resource name="setRoom" {...room} />
          <Resource name="setRoomcategory" {...roomcategory} />
          <Resource name="setBed" {...bed} />
          <Resource name="setAgentcontract" {...setagentcontract} />
          <Resource name="setAgent" {...setagent} />
          <Resource name="setAppopt" {...setappopt} />
          <Resource name="setSpecialday" {...specialday} />
          <Resource name="setAgecategory" {...agecategory} />
          <Resource name="setPrefcountry" {...prefcountry} />
          <Resource name="setPaymenttype" {...paymenttype} />
          <Resource name="setGuestcategory" {...guestcategory} />
          <Resource name="setGuestdetail" {...guestdetail} />
          <Resource name="setPwdyubi" {...setpwdyubi} />
          <Resource name="setDbuser" {...setdbuser} />
          <Resource name="setLoginlevel" />

          <Resource name="setRate" {...rate} />
          <Resource name="setPayrequest" {...payrequest} />
          <Resource name="setGuestfield" />
          <Resource name="setCondpayreq" />
          <Resource name="setCountry" />
          <Resource name="setSprache" />

          <Resource name="calYield" />
          <Resource name="calPricegr" {...calpricegr} />
          <Resource name="calPrice" edit={calpreis} />
          <Resource name="calPricegrcompare" {...calpricegrcompare} />
          <Resource name="calPricegrcomparerate" {...calpricecomparerate} />
          <Resource name="calPricegrcomparevat" {...pricegrcomparevat} />
          <Resource name="calMwst" {...calmwst} />
          <Resource name="calRate" {...calrate} /> 
          <Resource name="calOnline" {...calonline} />
          <Resource name="calOnlinecompare" {...calonlinecompare} />
          <Resource name="calOportal" {...caloportal} />
          <Resource name="calPortal" />
          <Resource name="calPortalroom" />
          <Resource name="calService" {...calservice} />

          <Resource name="globHostel" {...globhostel} />
          <Resource name="globServer" />
          <Resource name="setDbuserglob" {...setdbuser} />
          <Resource name="globUser" {...globuser}/>
          <Resource name="globTlogapi" {...tlogapi}/>
          <Resource name="globUsergroup" />
          <Resource name="globConstant/preisplan" />
          <Resource name="globConstant/reseller" />
          <Resource name="globConstant/rabattart" />
          <Resource name="globConstant/credituse" />
          <Resource name="globConstant/alllifecycle" />
          <Resource name="ifAktionen" />
          <Resource name="IfList" />

          <CustomRoutes>
            <Route path="/contact" element={<Contact />} />,
            <Route path="/infoarchiv" element={<Infoarchiv />} />,
            <Route path="/ereignisarchiv" element={<Ereignisarchiv />} />,
            <Route path="/profil" element={<Profil />} />,
            <Route path="/setTransfer" element={<SetTransfer />} />,
            <Route path="/calYield" element={<YieldEdit />} />,
            <Route path="/calCalendar" element={<CalendarEdit />} />,
            <Route path="/globServer" element={<GlobServer />} />,
            <Route path="/repBookingcom" element={<RepBookingcom />} />,
            <Route path="/repGdpr" element={<RepGdpr />} />,
            <Route path="/repGuestexport" element={<RepGuestexport />} />,
            <Route path="/ifList" element={<IfList />} />,

            <Route path="/ifPortal/*" element={<IfPortal />} />,
          </CustomRoutes>
        </Admin>
      </MenuConext.Provider>
    );
  }
}
export default App;
