import React, { useState } from 'react';
import { isHauptadmin, getInfoValue } from '../../lib/globalData';
import { useRecordContext, useListContext, useRedirect, Pagination, 
  List, Show, Datagrid, SimpleShowLayout, Toolbar, 
  TextField, DateField, CheckboxGroupInput, Button, 
  TextInput, ReferenceInput, AutocompleteInput, SelectInput, ShowButton, Filter }  from 'react-admin';
import { Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import dataProvider from '../../lib/dataProvider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Input } from '@mui/material';

const TextDisplay = ({ source, ...props }) => {
  const record = useRecordContext();
  return (
    <div style={{ fontSize: 'small' }} dangerouslySetInnerHTML={{ __html: record?.[source] }}></div>
  )
}

const ShowMessage = ({ classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const redirect = useRedirect();
  const {filterValues} = useListContext();
  
  const ShowActions = () => {
    return (
      <Toolbar>
        <Button label="ra.action.back" onClick={()=>redirect('/repMessages', { filter: {filterValues} })} variant="contained" size="medium" sx={{marginLeft:'1em'}}>
            <BackspaceIcon />
          </Button>
      </Toolbar>
    )
  }
  return (
    <Show {...props} actions={ <ShowActions /> } >
      <SimpleShowLayout>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <span style={{ fontSize: 'small', color: 'grey' }}>Zeit: </span>
            <DateField {...props} source="zeit" showTime locales="de-CH" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <span style={{ fontSize: 'small', color: 'grey' }}>Hostel: </span>
            <TextField {...props} source="id_hostel" label="Hostel" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
          {isHauptadmin() &&
            <div>
              <span style={{ fontSize: 'small', color: 'grey' }}>Id: </span>
              <TextField source="id" textAlign="right" label="Id" />
            </div>
          }
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <span style={{ fontSize: 'small', color: 'grey' }}>Betreff: </span>
            <TextField source="betreff"  label="Betreff" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <span style={{ fontSize: 'small', color: 'grey' }}>Receiver: </span>
            <TextField source="receiver" label="Receiver" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <span style={{ fontSize: 'small', color: 'grey' }}>Type: </span>
            <TextField source="type" label="Type" />
          </Grid>
        </Grid>
        <TextDisplay source="text"  label="Text:" />
      </SimpleShowLayout>
    </Show>
  )
};

const ListFilter = (props) => {
  const id_hostel = getInfoValue('id_hostel')
  const hostelname = getInfoValue('hostelname')
  const {filterValues, setFilters} = useListContext();
  const rChoices = [
    { id: '1', name: 'H_Manager (1)', en: true },
    { id: '2', name: 'H_Staff (2)', en: true },
    { id: '4', name: 'H_Info (4)', en: true },
    { id: '8', name: 'A_DB (8)', en: isHauptadmin() },
    { id: '16', name: 'A_Support (16)', en: isHauptadmin() },
    { id: '32', name: 'A_Progr (32)', en: isHauptadmin() },
    { id: '64', name: 'Blocked (64)', en: true },
  ]
  if(filterValues.id_hostel !== -1 && filterValues.id_hostel !== 0 && filterValues.id_hostel !== id_hostel){
    setFilters({...filterValues, id_hostel: id_hostel})
  }

  return (
    <Filter {...props} filterDefaultValues={{ id_hostel: id_hostel, tab: "tinfo" }}>
    {isHauptadmin() &&
      <SelectInput source="id_hostel" choices={[{ id: -1, name: "all Hostels" }, { id: 0, name: "[0] noHostel" }, { id: id_hostel, name: hostelname }]} alwaysOn />
    }
    {isHauptadmin() &&
      <ReferenceInput source="tab" reference="repMessages/tables" perPage={1000} alwaysOn >
        <SelectInput optionText="name" emptyValue="tinfo" size="small" label="Table" />
      </ReferenceInput>
    }
      <ReferenceInput source="type" reference="repMessages/typelist" perPage={1000} filter={filterValues} alwaysOn >
        <AutocompleteInput size="small" label="Type" optionText="type1" sx={{minWidth: '20em'}} />
      </ReferenceInput>
      <TextInput source="type2" alwaysOn label="Type" resettable/>
      <TextInput source="betreff" alwaysOn resettable/>
      <CheckboxGroupInput source="receiver" choices={rChoices.filter(c => c.en === true)} />
    </Filter>
  )
};

const TimezoneDateField = (props) => {
  const record = useRecordContext();
  if(!record?.htz)return (<div>Time zone not set</div>)
  return (
    <DateField source="zeit" label="Hostel time" showTime locales="de-CH" options={{ timeZone: record?.htz, timeZoneName:'shortOffset' }} />
  )
}

const CustomPagination = () => {
  const { page, perPage, setPage, total } = useListContext();
  const totalPages = Math.ceil(total / perPage);

  const handlePageChange = (event) => {
    const pageNumber = parseInt(event.target.value, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
        rowsPerPageOptions={[5,10,25,50,100,500]}
      />
      <Input
        type="number"
        value={page}
        onChange={handlePageChange}
        style={{ width: 50, marginLeft: 10 }}
      />
    </div>
  );
};

const ListForm = (props) => {
  const [emailConfirm, setEmailConfirm] = useState({id:'', tab:''});

  const handleSendEmail = (rec) => {
    console.log("email send")
    dataProvider.update('repMessages/sendmail', {
        id: rec?.id,
        data: {}
    })
      .then(response => {
        console.log(response)
        setEmailConfirm({id:'', tab:''})
      })
      .catch(response => {
        alert(response)
      });
  }

  const EmailButton = () => {
    const record = useRecordContext();
    if(!record?.email)return

    return (
      <Button onClick={() => {setEmailConfirm(record);console.log(["set email confirm", record])}} label="">
        <EmailIcon />
      </Button>
    )
  }

  return (
    <div>
      <List title="bw.set.specialday.title" filters={<ListFilter />} sort={{ field: 'zeit', order: 'DESC' }} exporter={isHauptadmin() && props.exporter} empty={false} pagination={<CustomPagination />} >
        <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
          <DateField source="zeit" label="Localtime" showTime locales="de-CH" options={{ timeZoneName:'shortOffset' }} />
          <TimezoneDateField source="zeit" label="Hostel time" />
          <TextField source="id_hostel" label="Hostel" />
          <TextField source="receiver" label="R" />
          <TextField source="type" />
          <TextField source="betreff" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
          <ShowButton label="" />
          <EmailButton />
        </Datagrid>
      </List>
      <Dialog open={emailConfirm?.id!==''} onClose={()=>setEmailConfirm({id:'', tab:''})} >
        <DialogTitle>Confirm </DialogTitle>
        <DialogContent sx={{"form > div":{margin:"16px 0"}}} >
          Do you really want to send this email?
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleSendEmail(emailConfirm)} color="primary">
            <b>Yes</b>
          </Button>
          <Button onClick={()=>setEmailConfirm({id:'', tab:''})} color="primary">
            <b>No</b>
          </Button>
        </DialogActions>
      </Dialog>              
    </div>
  )
}

const RepMessages = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} />
  ),

  show: ({ classes, ...props }) => (
    <ShowMessage {...props} classes={classes} />
  )
}
export default RepMessages